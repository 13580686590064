import { RSimTable } from './Components/RSimTable';
import { Outlet } from 'react-router-dom';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import Box from '@mui/material/Box';
import { ContentBox } from 'common/components/AppLayout/ContentBox';
import Grid from '@mui/material/Grid2';

import { TableWithFiltersLayout } from 'common/components/AppLayout/TableWithFiltersLayout';

import { RSimFiltersModal } from './Filters/RSimFiltersModal';
import { RSimFiltersChips } from './Filters/RSimFiltersChips';
import { TagProvider } from 'tags/TagProvider';
import { RefetchQueryButton } from 'common/components/Queries/RefetchQueryButton';
import { RSimFiltersProvider } from './Filters/RSimFiltersProvider';
import {
  TabBar,
  TabBarActions,
  TabBarChips,
  TabBarFilters,
} from 'common/components/AppLayout/TabBar/TabBar';
import { RSimIdFiltersSearchInput } from './Filters/RSimIdFiltersSearchInput';

export const RSimInventory = () => {
  return (
    <TagProvider type="rsim">
      <TableWithFiltersLayout
        filters={
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              borderBottom: 'none',
            }}
          >
            <TabBar>
              <Grid
                container
                rowGap={4}
                sx={{ mt: 0, width: '100%' }}
                justifyContent={'space-between'}
              >
                <TabBarFilters>
                  <Box sx={{ flex: 1, maxWidth: 360 }}>
                    <RSimIdFiltersSearchInput />
                  </Box>
                  <RSimFiltersModal />
                </TabBarFilters>
                <TabBarActions>
                  <RefetchQueryButton queryKey={['RSimInventory']} />
                </TabBarActions>
              </Grid>
              <TabBarChips>
                <RSimFiltersChips />
              </TabBarChips>
            </TabBar>
          </Box>
        }
      >
        <SuspenseLoadingView
          loadFallback={
            <ContentBox>
              <TableSkeleton showStartSkeleton numberOfRows={20} />
            </ContentBox>
          }
          queryKey={['RSimInventory']}
        >
          <RSimTable />
          <Outlet />
        </SuspenseLoadingView>
      </TableWithFiltersLayout>
    </TagProvider>
  );
};

export const RSimInventoryTab = () => {
  return (
    <RSimFiltersProvider>
      <RSimInventory />
    </RSimFiltersProvider>
  );
};
