import React, { useState } from 'react';
import { GridEditSingleSelectCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import { ValueDropdownModal } from '../../Components/ValueDropdownModal';
import { OptionType } from './ValueDropdownCell.interface';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Tooltip } from '../../../../common/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

export const ValueDropdownCell = (props: GridEditSingleSelectCellProps) => {
  const gridApiRef = useGridApiContext();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const onUpdateOptions = (options: OptionType[]) => {
    gridApiRef.current.setEditCellValue({
      id: props.row.id,
      field: 'selectionOptions',
      value: options,
    });
  };

  return (
    <>
      <TextField
        className={`${props.value.length === 0 && 'no-dropdown-values-error'}`}
        onClick={handleOpenDialog}
        value={props.value.map((option: OptionType) => option.label).join(', ')}
        inputProps={{ readOnly: true, sx: { cursor: 'pointer' } }}
        fullWidth
        placeholder={t('customFields.addDropdownListValues')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t('common.edit')} variant="dark" showArrow={true}>
                <IconButton aria-label="toggle password visibility" edge="end">
                  <EditOutlinedIcon sx={{ pt: 0, color: '#717676' }} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        data-testid={'customFieldDropdownValueTextField'}
      />
      {openDialog && (
        <DndProvider backend={HTML5Backend}>
          <ValueDropdownModal
            open={openDialog}
            // @ts-ignore
            onClose={handleCloseDialog}
            onUpdateOptions={onUpdateOptions}
            {...props}
          />
        </DndProvider>
      )}
    </>
  );
};
