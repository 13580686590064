import { reduce } from 'lodash';
import { cleanFalsyEntries } from './objectUtils';

export const getObjectFromUrlSearchParams = (searchParams: URLSearchParams) => {
  return Object.fromEntries(searchParams.entries());
};

export const getUpdatedUrlSearchParams = (
  key: string,
  value: string,
  currentSearchParams: URLSearchParams,
) => {
  const objectCurrentSearchParams = getObjectFromUrlSearchParams(currentSearchParams);
  const updatedSearchParams = { ...objectCurrentSearchParams, [key]: value };
  return cleanFalsyEntries(updatedSearchParams);
};

export const getUpdatedUrlSearchParamsWithObject = (
  object: Record<string, string>,
  currentSearchParams: URLSearchParams,
) => {
  const objectCurrentSearchParams = getObjectFromUrlSearchParams(currentSearchParams);
  const updatedSearchParams = { ...objectCurrentSearchParams, ...object };
  return cleanFalsyEntries(updatedSearchParams);
};

export const cleanDefaultValuesURLSearchParams = <T extends string>(
  searchParams: Record<T, string>,
  defaultValues: Record<T, string>,
) =>
  reduce(
    searchParams,
    (cleandFilters, value, key) => {
      //@ts-ignore
      //@ts-ignore
      if (value === defaultValues[key]) {
        return cleandFilters;
      }

      return {
        ...cleandFilters,
        [key]: value,
      };
    },
    {} as Record<T, string>,
  );

export type PaginationSerachParams = Record<'page' | 'rowsPerPage', string>;
export const cleanPaginationURLParams = <T extends string>(
  pagination: Record<T, string>,
  defaultValues: PaginationSerachParams = { page: '0', rowsPerPage: '25' },
) => cleanDefaultValuesURLSearchParams(pagination, defaultValues as Record<T, string>);
