import Big from 'big.js';
import { useModalFilters } from 'common/components/Filters/useModalFilters';
import { getUpdatedUrlSearchParamsWithObject } from 'common/utils/urlSearchParamsUtils';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFilters } from 'simInventory/Components/Filters/hooks/useFilters';
import { SimInventoryFilters, SimProfileId } from 'simInventory/models/SimProfile.model';

export const INITIAL_FILTERS: SimInventoryFilters & {
  idType: SimProfileId;
  searchQuery: string;
} = {
  sessionStatus: '',
  accounts: '',
  mobileNetworkOperator: '',
  label: '',
  connectionId: '',
  orderNumber: '',
  tags: '',
  iccidFrom: '',
  iccidTo: '',
  customFields: '',
  idType: 'ICCID',
  searchQuery: '',
};

const parseFiltersIccidRange = <T extends { iccidFrom: string; iccidTo: string }>(filters: T) => {
  const { iccidTo, iccidFrom, ...newFilters } = filters;
  if (iccidFrom === '' || iccidTo === '') {
    return filters;
  }

  const parsedIccidFrom = new Big(iccidFrom);
  const parsedToiccidTo = new Big(iccidTo);
  if (parsedIccidFrom.gt(parsedToiccidTo)) {
    return {
      ...newFilters,
      iccidFrom: '',
      iccidTo: '',
    };
  }

  return { ...filters };
};

export const useSimInventoryFiltersModal = () => {
  const { searchQuery, idType, filters, updateSearchQuery } = useFilters();
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersWithIds = useMemo(
    () => ({ ...filters, idType, searchQuery }),
    [searchQuery, idType, filters],
  );
  const { modalFilters, setModalFilters, cancel, open, openModal, setOpen } = useModalFilters(
    INITIAL_FILTERS,
    filtersWithIds,
  );

  const apply = () => {
    const { idType, searchQuery, ...filters } = modalFilters;
    updateSearchQuery(searchQuery);

    setSearchParams(
      getUpdatedUrlSearchParamsWithObject(
        {
          ...parseFiltersIccidRange(filters),
          sessionStatus: filters.sessionStatus?.toString(),
          chosenIdType: idType,
        },
        searchParams,
      ),
    );
  };

  const clear = () => {
    const { idType, searchQuery, ...filters } = INITIAL_FILTERS;

    updateSearchQuery('');
    setModalFilters(INITIAL_FILTERS);
    setSearchParams(
      getUpdatedUrlSearchParamsWithObject(
        {
          ...filters,
          sessionStatus: '',
          chosenIdType: idType,
        },
        searchParams,
      ),
    );
  };

  return {
    filtersActive:
      Object.values(filters).some((value) => value) ||
      !!searchQuery ||
      idType !== INITIAL_FILTERS.idType,
    apply,
    cancel,
    clear,
    modalFilters,
    setModalFilters,
    open,
    openModal,
    setOpen,
  };
};
