import Grid from '@mui/material/Grid2';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

type SecondarySimGridProps = {
  children: React.ReactElement;
  sx?: SxProps<Theme>;
  hideTopBorder?: boolean;
};

export const SecondarySimGrid: React.FC<SecondarySimGridProps> = ({
  children,
  sx,
  hideTopBorder,
}) => {
  const theme = useTheme();
  return (
    <Grid
      size={{ xs: 12, md: 6 }}
      sx={{
        [theme.breakpoints.up('md')]: {
          ml: 10,
        },
        pl: 3,
        pt: 3,
        pb: 3,
        borderTop: ({ palette }) => (!hideTopBorder ? `1px solid ${palette.tableBorder.main}` : ''),
        ...sx,
      }}
    >
      {children}
    </Grid>
  );
};
