import * as React from 'react';
import { Suspense, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import {
  DataGridPro,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowHeightParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import { CustomField, CustomFieldSelectedOption, FIELD_TYPE } from './entities/customFields.entity';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import { palette } from '../../theme/palette';
import { Button } from '../../common/components/Buttons/Button';
import { LabelTextFieldCell } from './tableCells/editCells/LabelTextFieldCell';
import { TypeDropdownCell } from './tableCells/editCells/TypeDropdownCell';
import { EnabledSwitchCell } from './tableCells/editCells/EnabledSwitchCell';
import Switch from '@mui/material/Switch';
import { TooltipCell } from './tableCells/TooltipCell';
import { ActionCell } from './tableCells/ActionCell';
import { useCustomFields } from './hooks/useCustomFields';
import { Dialog } from '../../common/components/Dialog/Dialog';
import { ValueDropdownCell } from './tableCells/editCells/ValueDropdownCell';
import { OptionType } from './tableCells/editCells/ValueDropdownCell.interface';
import { TextCell } from '../../common/components/Table/Cell/TextCell';
import {
  scrollbarHorizontalSX,
  scrollBarsSX,
  scrollbarVerticalSX,
} from '../../theme/utility/utility.styles';
import { Theme } from '@mui/material/styles';

const columnBaseOptions = {
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  resizable: false,
  disableReorder: true,
};

const DataGridStyled = styled(DataGridPro)({
  '&.MuiDataGrid-root': { border: 'none' },
  '& .MuiDataGrid-container--top [role=row]': {
    background: palette.grey[200],
    height: '42px',
    minHeight: '42px !important',
    maxHeight: '42px !important',
    lineHeight: '42px !important',
  },
  '& .MuiDataGrid-main>*:first-of-type': { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
  '& .MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center',
    '&.MuiDataGrid-cell--editing': {
      padding: '0 10px',
    },
  },
  '& .custom-fields-row-new, .MuiDataGrid-row.Mui-selected': {
    backgroundColor: palette.grey[300],
    '& .MuiDataGrid-cell--editing, .MuiDataGrid-cell': { backgroundColor: palette.grey[300] },
    '& .MuiTextField-root': {
      borderRadius: '12px',
    },
    '& .MuiInputBase-formControl': {
      backgroundColor: 'white',
      borderRadius: '12px',
    },
    '&: hover': {
      backgroundColor: palette.grey[300],
    },
  },
  '& .MuiDataGrid-actionsCell': {
    '& .Mui-disabled': { color: (theme: Theme) => `${theme.palette.tertiary.dark}` },
  },
  '& .textfield-error': {
    marginTop: '16px',
    '& fieldset': { border: `2px solid ${palette.failed.contrastText} !important` },
    svg: { color: palette.failed.contrastText, marginRight: '-10px' },
  },
  '& .fakeButton': {
    display: 'none',
  },
  '& .MuiDataGrid-row--editable:has(.textfield-error), .MuiDataGrid-row--editable:has(.missing-data), .MuiDataGrid-row--editable:has(.no-dropdown-values-error)':
    {
      '& .normalButton': {
        display: 'none',
      },
      '& .fakeButton': {
        display: 'flex',
      },
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader ': {
    paddingLeft: '22px',
  },
  '& .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell, .MuiDataGrid-row.Mui-selected:hover':
    {
      backgroundColor: 'white',
    },
  '& .MuiDataGrid-row.custom-fields-row-edit, .MuiDataGrid-row.custom-fields-row-edit .MuiDataGrid-cell, .MuiDataGrid-row.custom-fields-row-edit .MuiDataGrid-cell--editing, .custom-fields-row-edit.Mui-selected:hover':
    {
      backgroundColor: '#DCE4E6',
    },
  '& .MuiDataGrid-scrollbar--vertical::-webkit-scrollbar': {
    width: '14px',
  },
  '& .MuiDataGrid-scrollbar--horizontal': {
    ...scrollbarHorizontalSX,
    ...scrollBarsSX,
    bottom: '16px',
  },
  '& .MuiDataGrid-scrollbar--vertical': {
    ...scrollbarVerticalSX,
    ...scrollBarsSX,
  },
});

const GridContainerStyled = styled(Box)({
  background: palette.grey[200],
  width: '100%',
  height: 'calc(100% - 44px)', //44px is the size of Add button
});

const NEW_ROW_TEMP_ID = 0;

const isAddMode = (rows: CustomField[]) => {
  const isNewPresent = rows.find((r) => r.isNew);
  return !!isNewPresent;
};

const isEditMode = (rowModesModel: GridRowModesModel) => {
  return !!Object.keys(rowModesModel).length;
};

export const CustomFieldsPage = () => {
  const { t } = useTranslation();

  const { customFields, createField, editField, deleteField, editSelectionOptions } =
    useCustomFields();

  const [rows, setRows] = useState<CustomField[]>(customFields || []);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [customFieldToDelete, setCustomFieldToDelete] = useState<CustomField | null>(null);

  useEffect(() => {
    setRows(customFields || []);
  }, [customFields]);

  const preventMuiDefaultBehaviour: GridEventListener<
    'rowEditStop' | 'cellClick' | 'cellDoubleClick'
  > = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteIconClick = (id: GridRowId) => () => {
    const row = rows.find((r) => r.id === id);
    if (!!row) {
      setCustomFieldToDelete(row);
    }
  };

  const handleDeleteConfirmationClick = (id: GridRowId) => () => {
    setCustomFieldToDelete(null);
    deleteField(Number(id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (row: GridRowModel) => {
    if (row.id === NEW_ROW_TEMP_ID) {
      setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.View } });

      if (row.dataType === FIELD_TYPE.Text) {
        // add new field
        createField({
          label: row.label,
          enabled: row.enabled,
          selectionOptions: row.selectionOptions || [],
          dataType: row.dataType,
        });
      } else if (row.dataType === FIELD_TYPE.Dropdown) {
        createField({
          label: row.label,
          enabled: row.enabled,
          selectionOptions: row.selectionOptions.map((item: OptionType) => item.label),
          dataType: row.dataType,
        });
      }
    } else {
      editField({
        id: row.id,
        label: row.label,
        enabled: row.enabled,
      });

      if (row.dataType === FIELD_TYPE.Dropdown) {
        editSelectionOptions({ id: row.id, selectionOptions: row.selectionOptions });
      }
    }
    return row;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddNewRow = () => {
    const emptyField = {
      id: NEW_ROW_TEMP_ID,
      label: '',
      enabled: true,
      selectionOptions: [],
      isNew: true,
    };
    setRows((prev) => [...prev, emptyField]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [NEW_ROW_TEMP_ID]: { mode: GridRowModes.Edit, fieldToFocus: 'label' },
    }));
  };

  const columns: GridColDef[] = [
    {
      field: 'enabled',
      width: 130,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Switch data-testid="customFieldSwitch" checked={params.row.enabled} disabled={true} />
        );
      },
      renderEditCell: (params) => {
        return <EnabledSwitchCell {...params} />;
      },
      renderHeader: () => (
        <Typography data-testid="enabled-header" variant="text3" color="text.primary">
          {t('customFields.enabled')}
        </Typography>
      ),
      ...columnBaseOptions,
    },
    {
      field: 'label',
      width: 250,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <TextCell sx={{ ml: 3.5 }} text={params.row.label} testId="customFieldLabel" />;
      },
      renderEditCell: (params) => {
        return <LabelTextFieldCell {...params} tableRows={rows} />;
      },
      renderHeader: () => (
        <Typography data-testid="fieldName-header" variant="text3" color="text.primary">
          {t('customFields.fieldName')}
        </Typography>
      ),
      ...columnBaseOptions,
    },
    {
      field: 'dataType',
      width: 200,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TextCell
            sx={{ ml: 3.5 }}
            text={
              params.row.dataType === FIELD_TYPE.Text
                ? t('customFields.text')
                : t('customFields.dropdown')
            }
            testId="customFieldDataType"
          />
        );
      },
      preProcessEditCellProps: (params) => {
        return { ...params.props, isNew: params.otherFieldsProps?.isNew || false };
      },
      renderEditCell: (params) => {
        return <TypeDropdownCell {...params} variant="filled" />;
      },
      renderHeader: () => (
        <Typography data-testid="fieldType-header" variant="text3" color="text.primary">
          {t('customFields.fieldType')}
        </Typography>
      ),
      ...columnBaseOptions,
    },
    {
      field: 'selectionOptions',
      minWidth: 300,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.dataType === FIELD_TYPE.Text) {
          return <></>;
        }
        return (
          <TextCell
            sx={{ ml: 3.5 }}
            text={params.row.selectionOptions
              .sort((first: OptionType, second: OptionType) => first.order - second.order)
              .map((o: CustomFieldSelectedOption) => o.label)
              .join(', ')}
            testId="customFieldValue"
          />
        );
      },
      preProcessEditCellProps: (params) => {
        return {
          ...params.props,
          dataType: params.otherFieldsProps?.dataType.value,
          isNew: params.row?.isNew || false,
        };
      },
      renderEditCell: (params) => {
        if (
          params?.dataType === FIELD_TYPE.Dropdown ||
          params.row.dataType === FIELD_TYPE.Dropdown
        ) {
          return <ValueDropdownCell {...params} variant={'filled'} />;
        } else {
          return <></>;
        }
      },
      renderHeader: () => (
        <Typography data-testid="selectionOptions-header" variant="text3" color="text.primary">
          {t('customFields.values')}
        </Typography>
      ),
      ...columnBaseOptions,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      cellClassName: 'actions',
      resizable: false,
      disableReorder: true,
      renderHeader: () => (
        <Typography data-testid="actions-header" variant="text3" color="text.primary">
          {t('customFields.action')}
        </Typography>
      ),
      preProcessEditCellProps: (params) => {
        return {
          ...params.props,
          hasError: !params.otherFieldsProps?.label || !params.otherFieldsProps?.dataType,
        };
      },
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <ActionCell
              icon={<SaveIcon width="18px" height="18px" role="img" />}
              data-testid="customFieldsSaveButton"
              className="textPrimary normalButton"
              label={isAddMode(rows) ? t('customFields.create') : t('customFields.save')}
              onClick={handleSaveClick(params.id)}
              isCurrentRowActive={true}
            />,
            <ActionCell
              icon={<SaveIcon width="18px" height="18px" role="img" />}
              data-testid="customFieldsFakeSaveButton"
              className="textPrimary fakeButton"
              label={''}
              disabledLabel={t('customFields.saveError')}
              disabled={true}
              isCurrentRowActive={true}
            />,
            <ActionCell
              icon={<ClearIcon width="14px" height="14px" role="img" />}
              data-testid="customFieldsCancelButton"
              className="textPrimary"
              label={t('customFields.cancel')}
              onClick={handleCancelClick(params.id)}
              isCurrentRowActive={true}
            />,
          ];
        }

        if (params.id === 0) {
          return [];
        }

        return [
          <ActionCell
            icon={<EditOutlinedIcon width="18px" height="18px" role="img" />}
            data-testid="customFieldsEditButton"
            className="textSecondary"
            disabled={isEditMode(rowModesModel)}
            label={t('customFields.edit')}
            onClick={handleEditClick(params.id)}
            isCurrentRowActive={false}
          />,
          <ActionCell
            icon={<DeleteForeverOutlinedIcon width="14px" height="18px" role="img" />}
            data-testid="customFieldsDeleteButton"
            className="textSecondary"
            disabled={isEditMode(rowModesModel)}
            label={t('customFields.delete')}
            onClick={handleDeleteIconClick(params.id)}
            isCurrentRowActive={false}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <TooltipCell
        title={rows.length >= 10 ? t('customFields.customFieldsLimitReached') : null}
        disabled={isEditMode(rowModesModel)}
      >
        <Box sx={{ ml: 3, mt: 3, mb: 2 }}>
          <Button
            variant={'primary'}
            onClick={handleAddNewRow}
            disabled={isEditMode(rowModesModel) || rows.length >= 10 || isAddMode(rows)}
            data-testid="customFieldsAddButton"
            size="medium"
            iconEnd={<AddIcon width="12px" height="12px" role="img" />}
          >
            {t('customFields.addCustomField')}
          </Button>
        </Box>
      </TooltipCell>
      <GridContainerStyled>
        <DataGridStyled
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={preventMuiDefaultBehaviour}
          onCellClick={preventMuiDefaultBehaviour}
          onCellDoubleClick={preventMuiDefaultBehaviour}
          processRowUpdate={processRowUpdate}
          hideFooterPagination={true}
          hideFooter={true}
          disableVirtualization={true}
          getRowClassName={(params) =>
            params.row.isNew
              ? 'custom-fields-row-new'
              : rowModesModel[params.id]?.mode === GridRowModes.Edit
              ? 'Mui-selected custom-fields-row-edit'
              : ''
          }
          getRowHeight={({ id }: GridRowHeightParams) => {
            if (id === 0 || rowModesModel[id]?.mode === GridRowModes.Edit) {
              return 72;
            }
            return null;
          }}
        />
      </GridContainerStyled>

      {customFieldToDelete && (
        <Suspense>
          <Dialog
            open={!!customFieldToDelete}
            title={t('customFields.deleteCustomField')}
            onClose={() => setCustomFieldToDelete(null)}
            size="small"
            actions={
              <>
                <Button variant="secondary" onClick={() => setCustomFieldToDelete(null)}>
                  {t('common.cancel')}
                </Button>
                <Button
                  variant="primary"
                  onClick={handleDeleteConfirmationClick(customFieldToDelete?.id)}
                >
                  {t('common.confirm')}
                </Button>
              </>
            }
          >
            <Typography data-testid="deleteDialogDescription" variant="text1">
              {t('customFields.deleteCustomFieldDescription', { name: customFieldToDelete?.label })}
            </Typography>
          </Dialog>
        </Suspense>
      )}
    </>
  );
};
