import { FiltersChips } from 'common/components/Filters/FiltersChips';
import {
  LAST_ACTIVE_PROFILE_FILTER,
  LAST_UPDATE_TIME_FILTERS,
  LastActiveProfileFilter,
  LastUpdateTime,
  RSimFilters,
  SIMULATE_OUTAGE_FILTER,
  SimulateOutageFilter,
  ValidRSimFiltersKeys,
} from './data/filters.constants';
import { mapLastUpdateTimeToLabels } from './utils/lastUpdateTimeMappers';
import {
  mapLastActiveProfileFilterTLabel,
  mapSimulateOutageFilterToValue,
} from './utils/lastActiveProfileFilterMappers';
import React, { useContext, useMemo } from 'react';
import { convertStringToTags, convertTagsIdsToString } from 'tags/utils';
import { useTranslation } from 'react-i18next';
import { useRSimFilters } from './hooks/useRSimFilters';
import { FilterAsyncChip } from '../../common/components/FilterChip/FilterAsyncChip';
import { fetchAccount } from './services/accountsApi';
import { TagContext } from '../../tags/TagProvider';

type RSimChipsFilters = Omit<RSimFilters, 'searchText' | 'chosenIdType'>;

export const RSimFiltersChips = () => {
  const { filters, clearOne, clearMany, clearAll, updateOne } = useRSimFilters();

  const onTagRemoved = (id: string) => {
    const tags = convertStringToTags(filters.tags);
    const filteredTags = tags.filter((tag) => tag !== id);
    updateOne('tags', convertTagsIdsToString(filteredTags));
  };

  const onAccountRemoved = (id: string) => {
    const accounts = convertStringToTags(filters.accounts);
    const filteredAccounts = accounts.filter((account) => account !== id);
    updateOne('accounts', convertTagsIdsToString(filteredAccounts));
  };

  const onFilterClear = (filterName: string) => {
    if (filterName === 'eidRange') {
      clearMany(['eidFrom', 'eidTo']);
      return;
    }

    const rsimFilterName = filterName as ValidRSimFiltersKeys;
    clearOne(rsimFilterName);
  };

  const onFiltersClear = () => {
    clearAll();
  };

  const { t } = useTranslation();
  const createValuesForChips = (
    filters: Omit<RSimChipsFilters, 'tags' | 'accounts' | 'eidFrom' | 'eidTo'>,
  ) => {
    const filtersNames = {
      lastActiveProfile: 'Last active profile',
      lastUpdateTime: 'No update for',
      orderNumber: t('common.orderNumber'),
      connectionId: t('common.connectionId'),
      simulateOutage: t('common.simulateOutage'),
      eidRange: '',
    };

    const chipsValues: Partial<Record<keyof typeof filtersNames, string>> = {};

    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'chosenIdType' || key === 'searchText') {
        return;
      }

      if (
        (key === 'lastUpdateTime' && value === LAST_UPDATE_TIME_FILTERS.NONE) ||
        (key === 'lastActiveProfile' && value === LAST_ACTIVE_PROFILE_FILTER.ANY) ||
        (key === 'simulateOutage' && value === SIMULATE_OUTAGE_FILTER.ANY)
      ) {
        return;
      }

      if (key === 'lastUpdateTime' && value !== LAST_UPDATE_TIME_FILTERS.NONE) {
        const valueText = mapLastUpdateTimeToLabels(value as LastUpdateTime);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (key === 'lastActiveProfile' && value !== LAST_ACTIVE_PROFILE_FILTER.ANY) {
        const valueText = mapLastActiveProfileFilterTLabel(value as LastActiveProfileFilter);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (key === 'simulateOutage' && value !== SIMULATE_OUTAGE_FILTER.ANY) {
        const valueText = mapSimulateOutageFilterToValue(value as SimulateOutageFilter);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (value !== '') {
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${value}`;
      }
    });

    return chipsValues;
  };

  const { tags, accounts, eidFrom, eidTo, ...otherFilters } = filters;
  const filtersChips = createValuesForChips(otherFilters);

  if (eidFrom !== '' || eidTo !== '') {
    filtersChips.eidRange = `EID`;
    if (eidFrom) {
      filtersChips.eidRange += ` ${t('common.from')}: ${eidFrom}`;
    }

    if (eidTo) {
      filtersChips.eidRange += ` ${t('common.to')}: ${eidTo}`;
    }
  }

  const parsedTags = useMemo(() => {
    if (!tags) {
      return [];
    }
    return convertStringToTags(tags) || [];
  }, [tags]);

  const parsedAccounts = useMemo(() => {
    if (!accounts) {
      return [];
    }
    return convertStringToTags(accounts) || [];
  }, [accounts]);

  const parsedChips = [
    ...parsedTags.map((t) => ({ item: t, type: 'tag' })),
    ...parsedAccounts.map((a) => ({ item: a, type: 'account' })),
  ];

  const { fetchTag, type: tagType } = useContext(TagContext);
  return (
    <FiltersChips
      externalChips={
        parsedChips.length
          ? parsedChips.map((i) => (
              <React.Fragment key={i.item}>
                {i.type === 'tag' && (
                  <FilterAsyncChip
                    labelPrefix={'Tag'}
                    onCancel={() => onTagRemoved(i.item)}
                    fieldName="name"
                    queryFn={() => {
                      return fetchTag(i.item);
                    }}
                    queryKey={['tag', tagType, i.item]}
                    queryEnabled={!!i.item}
                  />
                )}
                {i.type === 'account' && (
                  <FilterAsyncChip
                    labelPrefix={'Account'}
                    onCancel={() => onAccountRemoved(i.item)}
                    fieldName="accountName"
                    queryFn={async () => {
                      return await fetchAccount(i.item);
                    }}
                    queryKey={['account', i.item]}
                    queryEnabled={!!i.item}
                  />
                )}
              </React.Fragment>
            ))
          : undefined
      }
      filters={filtersChips}
      onFilterClear={onFilterClear}
      onFiltersClear={onFiltersClear}
    />
  );
};
