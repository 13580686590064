import { NoRowsOverlay } from './Components/NoRowsOverlay';
import { CellConfiguration } from './Components/Cells/CellLoader';
import { DataGridProProps, GridPaginationModel } from '@mui/x-data-grid-pro';
import { SkeletonLoader } from './Components/Loaders/SkeletonLoader';

import { styled } from '@mui/material';
import { Configuration } from './hooks/usePrepareConfiguration';
import { SortingIndicator } from './Components/Buttons/SortIndicator';
import { ConfigurableTable } from './ConfigurableTable';
import { SecondaryColumnHeaders } from './Components/Rows/SecondaryColumnHeaders';

type ConfigurableTableProps = {
  disablePagination?: boolean;
  disableColumnReorder?: boolean;
  configuration: Configuration[];
  cellConfiguration: CellConfiguration;
  cellProps?: any;
  //TODO: Imporve typings (generic)
  rows?: Record<string, any>[];
  totalNumberOfItems?: number;
  actions?: any;
  'data-testid'?: string;
  page?: number;
  pageSize?: number;
  onPaginationChange?: (pagination: GridPaginationModel) => void;
  slots?: DataGridProProps['slots'];

  loading?: DataGridProProps['loading'];
  getRowId?: DataGridProProps['getRowId'];
  onSortModelChange?: DataGridProProps['onSortModelChange'];
  initialState?: DataGridProProps['initialState'];
};

//To remove when use this component in SimInventory
const CustomizeMuiDataGrid = styled(ConfigurableTable)(({ theme }) => ({
  '& .MuiDataGrid-skeletonHeaderRow': {
    backgroundColor: theme.palette.white,
  },
  '& .MuiDataGrid-container--top::after': {
    backgroundColor: theme.palette.white,
  },
  '& .MuiDataGrid-columnHeader': {
    borderBottom: 'none',
    backgroundColor: theme.palette.white,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.white,
    '& > *': {
      backgroundColor: `${theme.palette.white} !important`,
    },
  },
  '& .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.tableBorder.main}`,
  },
  '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
    '& .MuiDataGrid-cell': {
      borderBottom: `none`,
    },
  },
  '& .MuiDataGrid-bottomContainer': {
    border: 'none',
    height: 0,
  },
  '& .MuiDataGrid-columnHeader--pinnedRight': {
    backgroundColor: `${theme.palette.white} !important`,
  },
}));

export const SecondaryConfigurableTable = ({ slots, ...props }: ConfigurableTableProps) => {
  return (
    <CustomizeMuiDataGrid
      {...props}
      disablePagination
      slots={{
        columnHeaderSortIcon: SortingIndicator,
        noRowsOverlay: NoRowsOverlay,
        loadingOverlay: () => <SkeletonLoader numberOfRows={2} />,
        row: undefined,
        columnHeaders: SecondaryColumnHeaders,
        ...slots,
      }}
    />
  );
};
