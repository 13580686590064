import Box from '@mui/material/Box';
import { SimInventoryTable } from './Components/SimInventoryTable';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { FiltersModal } from './FiltersModal/SimInventoryFiltersModal';
import { simsInventorySearchDataTestId } from './SimInventoryDataTestIds';

import { ContentBox } from 'common/components/AppLayout/ContentBox';
import { TableWithFiltersLayout } from 'common/components/AppLayout/TableWithFiltersLayout';
import { Suspense, useState } from 'react';
import { SimInventoryFilterChips } from './FiltersModal/SimInventoryFilterChips';
import { TagProvider } from 'tags/TagProvider';
import { convertStringToTags, convertTagsIdsToString } from 'tags/utils';
import { RefetchQueryButton } from 'common/components/Queries/RefetchQueryButton';
import { SimIdSearchInput } from './Components/Inputs/SimIdSearchInput';
import { FiltersProvider } from './Components/Filters/FiltersProvider';
import { useFilters } from './Components/Filters/hooks/useFilters';
import { removeItemFromCustomFieldFilter } from '../common/components/Filters/CustomFieldsFilter/customFields.utils';
import { INITIAL_FILTERS } from './FiltersModal/hooks/useSimInventoryFiltersModal';
import { SimProfileDownloadReportsExtendedFiltersButton } from './Reports/SimProfileDownloadReportsExtendedFiltersButton';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';
import {
  TabBar,
  TabBarActions,
  TabBarChips,
  TabBarFilters,
} from 'common/components/AppLayout/TabBar/TabBar';
import Grid from '@mui/material/Grid2';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { SimProfileDownloadReportsExtendedFiltersButtonLargeExport } from './Reports/SimProfileDownloadReportsExtendedFiltersButtonLargeExport';

export const SimInventory = () => {
  const { idType, updateIdType, ids, filters, setFilters, searchQuery, updateSearchQuery } =
    useFilters();

  const filtersParams = { searchText: ids, filters, chosenIdType: idType };
  const largeReportsEnabled = useHasFeatureFlag('LargeReports');

  const [loading, setLoading] = useState<boolean>(true);

  return (
    <TagProvider type="sim">
      <TableWithFiltersLayout
        filters={
          <TabBar>
            <Grid
              container
              rowGap={4}
              sx={{ mt: 0, width: '100%' }}
              justifyContent={'space-between'}
            >
              <TabBarFilters>
                <Box sx={{ flex: 1, maxWidth: 360 }}>
                  <SimIdSearchInput
                    disabled={!!filters.iccidFrom || !!filters.iccidTo}
                    chosenId={idType}
                    onChosenIdChange={updateIdType}
                    search={searchQuery || ''}
                    onSearchChange={(searchText) => {
                      updateSearchQuery(searchText);
                    }}
                  />
                </Box>
                <Suspense>
                  <div data-testid={simsInventorySearchDataTestId}>
                    <FiltersModal />
                  </div>
                </Suspense>
              </TabBarFilters>
              <TabBarActions>
                <RefetchQueryButton queryKey={['SimInventoryConfigurable']} />
                <SimInventoryCan I={Actions.export} a={Subjects.simsProfiles}>
                  {largeReportsEnabled ? (
                    <SimProfileDownloadReportsExtendedFiltersButtonLargeExport disabled={loading} />
                  ) : (
                    <SimProfileDownloadReportsExtendedFiltersButton
                      searchParams={filtersParams}
                      disabled={loading}
                    />
                  )}
                </SimInventoryCan>
              </TabBarActions>
            </Grid>
            <TabBarChips>
              <SimInventoryFilterChips
                filters={filters}
                onFiltersClear={() => {
                  setFilters(INITIAL_FILTERS);
                }}
                onFilterClear={(key: string) => {
                  if (key === 'iccidRange') {
                    setFilters({ ...filters, iccidFrom: '', iccidTo: '' });
                  } else {
                    setFilters({ ...filters, [key]: '' });
                  }
                }}
                onTagRemoved={(id) => {
                  const tags = convertStringToTags(filters.tags);
                  const filteredTags = tags.filter((tag) => tag !== id);
                  setFilters({ ...filters, tags: convertTagsIdsToString(filteredTags) });
                }}
                onMnoRemoved={(id) => {
                  const mnos = convertStringToTags(filters.mobileNetworkOperator);
                  const filteredMnos = mnos.filter((mno) => mno !== id);
                  setFilters({
                    ...filters,
                    mobileNetworkOperator: convertTagsIdsToString(filteredMnos),
                  });
                }}
                onAccountRemoved={(id) => {
                  const accounts = convertStringToTags(filters.accounts);
                  const filteredAccounts = accounts.filter((account) => account !== id);
                  setFilters({ ...filters, accounts: convertTagsIdsToString(filteredAccounts) });
                }}
                onCustomFieldRemoved={(fieldId, optionId) => {
                  setFilters({
                    ...filters,
                    customFields:
                      removeItemFromCustomFieldFilter(filters.customFields, fieldId, optionId) ||
                      '',
                  });
                }}
              />
            </TabBarChips>
          </TabBar>
        }
      >
        <SuspenseLoadingView
          loadFallback={
            <ContentBox>
              <TableSkeleton showStartSkeleton showEndSkeleton numberOfRows={20} />
            </ContentBox>
          }
          queryKey={['SimInventoryConfigurable']}
        >
          <SimInventoryTable setLoading={setLoading} />
        </SuspenseLoadingView>
      </TableWithFiltersLayout>
    </TagProvider>
  );
};

export const SimInventoryTab = () => {
  return (
    <FiltersProvider>
      <SimInventory />
    </FiltersProvider>
  );
};
