import { fetchRSimInventory } from 'rSimInventory/RSimApi/rSimApi';
import { useSuspenseQuery } from '@tanstack/react-query';
import { fetchSimInventoryEntry } from 'simInventory/SimInventoryApi/simInventoryApi';

export const useRSimSessionImsis = (euicc: string) => {
  const { data: imsis } = useSuspenseQuery({
    queryKey: ['RSimSimProfilesIccidsByEuicc', euicc],
    queryFn: async () => {
      const rsimInventory = await fetchRSimInventory(0, 1, undefined, euicc);
      if (rsimInventory.items.length === 0) {
        return {};
      }

      const rsimProfile = rsimInventory.items[0];

      const fetchPromise = [];
      if (rsimProfile.primaryIccid) {
        fetchPromise.push(fetchSimInventoryEntry(rsimProfile.primaryIccid));
      }

      if (rsimProfile.secondaryIccid) {
        fetchPromise.push(fetchSimInventoryEntry(rsimProfile.secondaryIccid));
      }

      const [primarySimProfile, secondarySimProfile] = await Promise.all(fetchPromise);

      return {
        primarySimProfile: primarySimProfile.imsi,
        secondarySimProfile: secondarySimProfile.imsi,
        primaryIccid: rsimProfile.primaryIccid,
        secondaryIccid: rsimProfile.secondaryIccid,
      };
    },
  });

  return imsis;
};
