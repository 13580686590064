import React, { useEffect, useRef, useState } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TextField from '@mui/material/TextField';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { greyColor, typoColor } from '../../../theme/palette';
import { OptionType } from '../tableCells/editCells/ValueDropdownCell.interface';
import Box from '@mui/material/Box';
import { useDrag, useDrop } from 'react-dnd';
import InputAdornment from '@mui/material/InputAdornment';
import { Tooltip } from '../../../common/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

export const ValueDropdownOption = ({
  option,
  options,
  onRemoveOptionHandler,
  optionIndex,
  onChangeLabelHandler,
  handleDrop,
}: {
  option: OptionType;
  options: OptionType[];
  onRemoveOptionHandler: (currentOption: OptionType) => void;
  optionIndex: number;
  onChangeLabelHandler: (optionIndex: number, newLabel: string) => void;
  handleDrop: (optionToChange: OptionType, newIndex: number) => void;
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [readOnly, setReadOnly] = useState(true);
  const [valueToChange, setValueToChange] = useState<string>(option.label);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dragRef, dragPreview] = useDrag(
    () => ({
      type: 'menuItem',
      item: option,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [option],
  );

  const [{ isOver }, dropRef] = useDrop({
    accept: 'menuItem',
    drop: (item: OptionType) => {
      handleDrop(item, optionIndex);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (!readOnly) inputRef.current && inputRef.current.focus();
  }, [readOnly]);

  const labelAlreadyExistsError = options.some(
    (currentOption, currentIndex) =>
      currentIndex !== optionIndex &&
      currentOption.label.toLowerCase() === valueToChange.toLowerCase(),
  );

  return (
    <Box ref={dropRef}>
      <Box ref={dragPreview}>
        <Box
          ref={dragRef}
          sx={{
            mt: optionIndex === 0 ? 1 : 2,
            pt: 2,
            borderTop: `2px solid ${isOver ? greyColor[600] : typoColor.white}`,
          }}
        >
          <TextField
            autoComplete={'off'}
            data-testid={'customFieldsDropdownOption'}
            inputRef={inputRef}
            error={!readOnly && labelAlreadyExistsError}
            helperText={
              !readOnly && labelAlreadyExistsError
                ? t('customFields.dropdownModal.valueNameTaken')
                : ''
            }
            fullWidth
            inputProps={{ readOnly: readOnly, sx: { cursor: readOnly ? 'default' : 'text' } }}
            value={!readOnly ? valueToChange : option.label}
            focused={!readOnly}
            onChange={(e) => setValueToChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'} sx={{ pl: 2, cursor: 'grab' }}>
                  <DragIndicatorIcon sx={{ pt: 0, color: '#717676' }} />
                </InputAdornment>
              ),
              endAdornment: readOnly ? (
                <InputAdornment position="end" sx={{ pr: 2 }}>
                  <Tooltip title={t('common.edit')} variant="dark" showArrow={true}>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setReadOnly(false);
                      }}
                      aria-label={'Edit option'}
                    >
                      <EditOutlinedIcon sx={{ pt: 0, color: '#717676' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('common.delete')} variant="dark" showArrow={true}>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveOptionHandler(option);
                      }}
                      aria-label={'Remove option'}
                    >
                      <DeleteForeverOutlinedIcon sx={{ pt: 0, color: '#717676' }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : (
                <InputAdornment position={'end'} sx={{ pr: 2 }}>
                  <Tooltip title={t('common.confirm')} variant="dark" showArrow={true}>
                    <IconButton
                      disabled={!valueToChange || labelAlreadyExistsError}
                      sx={{ p: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChangeLabelHandler(optionIndex, valueToChange);
                        setReadOnly(true);
                      }}
                      aria-label={'Confim edit option'}
                    >
                      <DoneOutlinedIcon sx={{ pt: 0, color: '#717676' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('common.cancel')} variant="dark" showArrow={true}>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setReadOnly(true);
                      }}
                    >
                      <CloseOutlinedIcon sx={{ pt: 0, color: '#717676' }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
