import { AdditionalMenu } from 'common/components/AdditionalMenu';
import MenuList from '@mui/material/MenuList';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export type ActionDef = {
  enabled?: boolean | (() => boolean);
  icon?: React.ReactNode;
  label: 'string';
  slotProps: {
    menuItem: MenuItemProps;
  };
};

export type ActionCellProps<T> = {
  data: T;
  actions: ActionDef[];
};

export const ActionCell = <T,>({ actions, data }: ActionCellProps<T>) => {
  return (
    <AdditionalMenu>
      {({ onClose }) => (
        <MenuList>
          {actions
            .filter((action: any) => {
              let result;
              if (typeof action.enabled === 'function') {
                result = action.enabled(data);
              } else {
                result = action.enabled;
              }
              // != -> used here becuase only for false value we want false for others ex. undefiend need true
              // eslint-disable-next-line eqeqeq
              return result != false;
            })
            .map((action: any) => {
              return (
                <MenuItem
                  {...action.slotProps?.menuItem}
                  onClick={() => {
                    action.actionCallback(data);
                    if (action.closable) {
                      onClose();
                    }
                    onClose();
                  }}
                >
                  {action.icon ? <ListItemIcon>{action.icon}</ListItemIcon> : null}
                  <ListItemText>{action.label}</ListItemText>
                </MenuItem>
              );
            })}
        </MenuList>
      )}
    </AdditionalMenu>
  );
};
