import * as React from 'react';
import { useEffect } from 'react';
import {
  CustomField,
  FIELD_TYPE,
} from '../../../../admin/customFields/entities/customFields.entity';
import { AutocompleteSelect, Option } from '../../Inputs/AutocompleteSelect/AutocompleteSelect';
import {
  mapOptionsToString,
  TextFieldWithHints,
} from '../../Inputs/TextFieldWithHints/TextFieldWithHints';
import { getValuesForFieldById } from './customFields.utils';
import { useTranslation } from 'react-i18next';

type CustomFieldsFilterProps = {
  field: CustomField;
  currentFilter: string;
  onTextChange: (value: string | null) => void;
  onDropdownChange: (value: Option[]) => void;
  hints: { [key: string]: Option[] };
  onRemoveHint: (hint: string) => void;
};

export const CustomFieldsFilter: React.FC<CustomFieldsFilterProps> = ({
  field,
  currentFilter,
  onTextChange,
  onDropdownChange,
  hints,
  onRemoveHint,
}) => {
  const { t } = useTranslation();

  const value: string[] = getValuesForFieldById(currentFilter, field.id.toString());

  useEffect(() => {
    if (hints[field.id.toString()] && field.selectionOptions?.length > 0) {
      const exclude: Option[] = hints[field.id.toString()].filter(
        (hint) =>
          field.selectionOptions
            ?.map((option) => option.id?.toString())
            .indexOf(hint.id.toString()) === -1,
      );
      exclude.forEach((hint) => onRemoveHint(hint.id));
    }
  }, [hints, field, onRemoveHint]);

  if (field.dataType === FIELD_TYPE.Text) {
    return (
      <TextFieldWithHints
        testId={`customField-text-${field.id}`}
        placeholder={`${t('simInventory.search')} ${field.label}`}
        value={value[0] || ''}
        onChange={(value) => onTextChange(value)}
        hints={hints[field.id.toString()] ? mapOptionsToString(hints[field.id.toString()]) : []}
        onRemoveHint={(hint: string) => onRemoveHint(hint)}
      />
    );
  }

  return (
    <AutocompleteSelect
      testId={`customField-dropdown-${field.id}`}
      hints={hints && hints[field.id.toString()] ? hints[field.id.toString()] : []}
      values={value}
      options={field.selectionOptions.map((o) => ({
        id: o.id?.toString() || '',
        name: o.label || '',
      }))}
      onChange={(value) => onDropdownChange(value)}
      allowAdding={false}
      onRemoveHint={(hint: Option) => onRemoveHint(hint.id)}
      placeholder={`${t('simInventory.select')} ${field.label}`}
    />
  );
};
