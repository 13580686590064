import { SimInventoryData, SimProfile } from '../SimInventoryApi/simInventoryApi.interface';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSimInventorySubscription } from '../hooks/useSimInventorySubscription';
import { Sorting } from 'common/hooks/useSorting';
import { useSimInventoryFetch } from 'simInventory/hooks/useSimInventoryFetch';
import { SimInventoryFilters, SimProfileId } from 'simInventory/models/SimProfile.model';

export const useSimInventory = (
  searchText: string[],
  chosenIdType: SimProfileId,
  filters: SimInventoryFilters,
  sort: { columnName: string; sort: Sorting } | null = null,
) => {
  const {
    simInventory,
    isRefetching,
    pagination: { page, setPage, rowsPerPage, setRowsPerPage, totalNumberOfItems },
    queryKey,
    isLoading,
  } = useSimInventoryFetch(searchText, chosenIdType, filters, sort);

  const queryClient = useQueryClient();

  const simProfiles = simInventory?.items || [];

  const updateTagsForSim = useCallback(async (iccid: string, tags: any) => {
    const simProfiles: SimInventoryData | undefined = queryClient.getQueryData(queryKey);

    if (!simProfiles?.items.length) {
      return;
    }

    //@ts-ignore
    queryClient.setQueryData(queryKey, (oldData: SimInventoryData) => {
      if (!oldData) {
        return oldData;
      }

      const newData = {
        ...oldData,
        items: oldData?.items.map((simProfile: SimProfile) => {
          if (simProfile.iccid === iccid) {
            return { ...simProfile, tags: { items: tags, hasMore: false } };
          }

          return simProfile;
        }),
      };

      return newData;
    });
  }, []);

  const iccids = useMemo(() => {
    const simProfiles = simInventory?.items || [];
    return simProfiles.map((simProfile) => simProfile.iccid);
  }, [simProfiles]);
  useSimInventorySubscription(iccids, queryKey);

  return {
    pagination: {
      totalNumberOfItems,
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
    },
    simInventory,
    isRefetching,
    updateTagsForSim,
    isLoading,
  };
};
