import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import {
  useGridApiContext,
  useGridSelector,
  gridPaginationModelSelector,
  gridPaginationRowCountSelector,
} from '@mui/x-data-grid-pro';

const PaginationRoot = styled('div')({ flex: 1, position: 'relative' });

const PaginationBorder = styled('div')<{ bottomContainerHeight: number }>(
  ({ theme, bottomContainerHeight }) => ({
    position: 'absolute',
    border: `1px solid ${theme.palette.tableBorder.main}`,
    borderTop: 'none',
    top: -bottomContainerHeight,
    left: 0,
    right: 0,
    height: `${bottomContainerHeight}px`,
    pointerEvents: 'none',
  }),
);

export const Pagination = () => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPaginationRowCountSelector);
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);

  let bottomContainerHeight = 0;
  if (
    apiRef.current.state?.dimensions?.root &&
    apiRef.current.state?.dimensions?.minimumSize &&
    apiRef.current.state?.dimensions?.root.height >
      apiRef.current.state?.dimensions?.minimumSize.height
  ) {
    bottomContainerHeight =
      apiRef.current.state?.dimensions?.root.height -
      apiRef.current.state?.dimensions?.minimumSize.height;
  }

  return (
    <PaginationRoot>
      <PaginationBorder bottomContainerHeight={bottomContainerHeight} />
      <Box sx={{ display: 'flex' }}>
        <TablePagination
          sx={{
            border: ({ palette }) => `1px solid ${palette.tableBorder.main}`,
            borderTop: 'none',
          }}
          color="primary"
          showLastButton={false}
          count={pageCount}
          page={paginationModel.page}
          rowsPerPageOptions={[25, 50, 100]}
          onPageChange={(event, newPage) => {
            apiRef.current.setPage(newPage);
            apiRef.current.setExpandedDetailPanels([]);
          }}
          rowsPerPage={paginationModel.pageSize}
          onRowsPerPageChange={(event) => {
            apiRef.current.setPageSize(parseInt(event.target.value));
          }}
        />
      </Box>
    </PaginationRoot>
  );
};

// export const Pagination = (props: any) => {
//   return <GridPagination ActionsComponent={CustomPagination} {...props} />;
// };
