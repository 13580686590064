import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ScrollArea } from 'common/components/ScrollArea';
import { useInfinitySessionHistory } from './hooks/useInfinitySessionHistory';
import { SessionHistoryHeaderRow } from './Components/SessionHistoryHeaderRow';
import { SessionHistoryRowWithInterim } from './Components/SessionHistoryRowWithInterim';
import { EmptyTableMessage } from 'common/components/EmptyTableMessage';
import { useTranslation } from 'react-i18next';
import { ContentBox } from 'common/components/AppLayout/ContentBox';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';

export const SessionHistoryTableWithInterims = () => {
  const { iccid } = useParams();
  const { sessionHistory, showLoader, refToLoader, fetchingEnded, isLoading } =
    useInfinitySessionHistory(iccid);

  const { t } = useTranslation();
  const getOpenedRowsMap = () => {
    return (
      sessionHistory.reduce((current, item) => {
        return { ...current, [item.id]: false };
      }, {}) || {}
    );
  };
  const [opendRows, setOpendRows] = useState<Record<string, boolean>>(getOpenedRowsMap());

  const collapseAllButtonVisible = Object.values(opendRows).some((opened) => opened);

  return !isLoading ? (
    <Box
      sx={{ height: '100%', backgroundColor: sessionHistory.length === 0 ? 'white' : '#F9F9F9' }}
    >
      <ScrollArea>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '48px repeat(14, max-content) auto',
          }}
        >
          <SessionHistoryHeaderRow
            collapseAllButtonVisible={collapseAllButtonVisible}
            onCollapseAllClick={() => setOpendRows(getOpenedRowsMap())}
          />
          <Box
            sx={{
              display: 'grid',
              gridColumn: 'span 16',
              gridTemplateColumns: 'subgrid',
              borderBottom: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              '&>*': {
                borderLeft: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                borderRight: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              },
              '&>div:first-child': {
                borderTop: 'none',
              },
              '&>.opened': {
                borderBottom: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              },
              '&>* + .opened': {
                mt: 3,
              },
              '&>*:has(+  .opened)': {
                borderBottom: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              },
              '&>.opened + *': {
                mt: 3,
              },
              '&>*+*': {
                borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              },
            }}
          >
            {sessionHistory.map((row) => {
              return (
                <SessionHistoryRowWithInterim
                  {...row}
                  imsi={row.imsi}
                  opened={opendRows?.[row.id] || false}
                  onToggle={(open: boolean) => {
                    setOpendRows((currentVal) => ({ ...currentVal, [row.id]: open }));
                  }}
                />
              );
            })}
            {showLoader && (
              <Box
                sx={{ py: 2, gridColumn: 'span 16', background: (theme) => theme.palette.white }}
              >
                <div ref={refToLoader}>
                  <Box
                    sx={{
                      position: 'sticky',
                      left: '50vw',
                      width: 'min-content',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                </div>
              </Box>
            )}
          </Box>
          {sessionHistory.length === 0 && fetchingEnded ? (
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                width: 'min-content',
                transform: 'translateX(-50%) translateY(-50%)',
              }}
            >
              <EmptyTableMessage message={t('common.noResults')} />
            </Box>
          ) : null}
        </Box>
      </ScrollArea>
    </Box>
  ) : (
    <ContentBox>
      <TableSkeleton numberOfRows={20} pagination={false} />
    </ContentBox>
  );
};
