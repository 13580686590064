import { FC } from 'react';
import { ReactComponent as Arrow } from 'assets/images/arrowBack.svg';
import { t } from 'i18next';
import Box from '@mui/material/Box';
import { Tooltip } from '../../../Tooltip/Tooltip';

export type SortingIndicatorProps = {
  direction?: 'asc' | 'desc' | null;
};

export const SortingIndicator: FC<SortingIndicatorProps> = ({ direction }) => {
  const style = {
    transform: direction === 'desc' ? 'rotate(270deg)' : 'rotate(90deg)',
    transition: 'transform 150ms ease',
  };

  const label =
    direction === 'asc'
      ? t('common.asc')
      : direction === 'desc'
      ? t('common.desc')
      : t('common.toSort');

  return (
    <Box
      aria-label={label}
      role="button"
      //improve when extend secondary
      data-testid="primarySortingIndicator"
      sx={{
        display: 'inline-flex',
        '&:hover': {
          background: '#E5E5E5',
          borderRadius: 2000,
        },
      }}
    >
      <Tooltip
        showArrow={true}
        placement={'top'}
        enterDelay={1000}
        enterTouchDelay={1000}
        enterNextDelay={1000}
        variant="dark"
        title={
          !direction || direction === 'desc'
            ? t('common.sortByAscending')
            : t('common.sortByDescending')
        }
      >
        <Arrow style={style} width="16px" height="16px" role="img" viewBox="0 0 24 24" />
      </Tooltip>
    </Box>
  );
};
