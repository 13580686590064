import { atom } from 'jotai';

import { focusAtom } from 'jotai-optics';
import { atomFamily } from 'jotai/utils';
import { Routes } from 'Routes.constants';

export const navigationAtom = atom({
  activePage: 'inventory',
  pages: {
    inventory: {
      activeTab: 'Sim',
      tabs: [
        {
          name: 'Sim',
          pathname: Routes.simInventory,
        },
        {
          name: 'RSim',
          pathname: Routes.rSimInventory,
        },
      ],
    },
    provisioning: {
      activeTab: 'Bulk provisioning',
      tabs: [
        {
          name: 'Bulk provisioning',
          pathname: Routes.provisioningRequest,
        },
      ],
    },
    admin: {
      activeTab: 'Custom fields',
      tabs: [
        {
          name: 'Custom fields',
          pathname: Routes.customFields,
        },
        {
          name: 'RSim Manufacturing File',
          pathname: Routes.rsimManufacturingFile,
        },
      ],
    },
  },
  tabs: [
    {
      name: 'Sim',
      page: 'inventory',
      pathname: Routes.simInventory,
    },
    {
      name: 'RSim',
      page: 'inventory',
      pathname: Routes.rSimInventory,
    },
    {
      page: 'provisioning',
      name: 'Bulk provisioning',
      pathname: Routes.provisioningRequest,
    },
    {
      name: 'Custom fields',
      page: 'admin',
      pathname: Routes.customFields,
    },
    {
      name: 'RSim Manufacturing File',
      page: 'admin',
      pathname: Routes.rsimManufacturingFile,
    },
  ],
});

export const activePageAtom = focusAtom(navigationAtom, (optic) => optic.prop('activePage'));

export const pagesAtom = focusAtom(navigationAtom, (optic) => optic.prop('pages'));

export const tabsAtom = focusAtom(navigationAtom, (optic) => optic.prop('tabs'));

export const inventoryPageAtom = focusAtom(pagesAtom, (optic) => optic.prop('inventory'));

export const pageTabAtom = atomFamily((name: 'inventory' | 'provisioning' | 'admin') => {
  return focusAtom(pagesAtom, (optic) => optic.prop(name));
});
