import React, { createContext, useMemo } from 'react';
import { createContextualCan } from '@casl/react';

import { AnyAbility, defineAbility } from '@casl/ability';
import { useQuery } from '@tanstack/react-query';
import {
  fetchAuditTrailsPermissions,
  fetchBulkProvisioninPermissions,
  fetchSimInventoryPermissions,
} from './api/permissionsApi';
import {
  createAuditTrailsPermissionsFromRules,
  createBulkProvisioningPermissionsFromRules,
  createSimInventoryPermissionsFromRules,
} from './ability';
import {
  getAuditTrailsRulesByRole,
  getBulkRulesByRole,
  getSimInventoryRulesByRole,
} from './rolePermissions';
import { useAuth } from 'auth/AuthProvider';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { FEATURE_FLAGS_MODULES } from 'featureFlags/FeatureFlags.models';
import { AuditTrailsPermissions } from './Permissions.model';

export const AbilityContext = createContext<AnyAbility>({} as AnyAbility);

export const AbilityContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const simInventoryPermissionsEnabled = useHasFeatureFlag(
    'PermissionBasedAccessControl',
    FEATURE_FLAGS_MODULES.SIM_INVENTORY,
  );
  const bulkProvisioninPermissionsEnabled = useHasFeatureFlag(
    'PermissionBasedAccessControl',
    FEATURE_FLAGS_MODULES.BULK_PROVISIONING,
  );

  const auditTrailPermissionsEnabled = useHasFeatureFlag(
    'PermissionBasedAccessControl',
    FEATURE_FLAGS_MODULES.AUDIT_TRAIL,
  );

  const { user } = useAuth();
  const { data: permissions, isFetched } = useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const simInventoryPermissions = simInventoryPermissionsEnabled
        ? await fetchSimInventoryPermissions()
        : getSimInventoryRulesByRole(user.role);

      const bulkPermissions = bulkProvisioninPermissionsEnabled
        ? await fetchBulkProvisioninPermissions()
        : getBulkRulesByRole(user.role);

      let auditTrails: AuditTrailsPermissions[] = [];
      try {
        auditTrails = auditTrailPermissionsEnabled
          ? await fetchAuditTrailsPermissions()
          : getAuditTrailsRulesByRole();
      } catch (err) {
        auditTrails = [];
      }

      return {
        simInventoryPermissions,
        bulkPermissions,
        auditTrails,
      };
    },
  });

  const ability = useMemo(() => {
    if (permissions) {
      //@ts-ignore
      return defineAbility((can) => {
        createSimInventoryPermissionsFromRules(permissions.simInventoryPermissions, can);
        createBulkProvisioningPermissionsFromRules(permissions.bulkPermissions, can);

        createAuditTrailsPermissionsFromRules(permissions.auditTrails, can);
      });
    } else {
      return defineAbility(() => {});
    }
  }, [permissions]);

  return isFetched ? (
    <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
  ) : null;
};

export const SimInventoryCan = createContextualCan(AbilityContext.Consumer);
