import { ErrorCodes } from 'common/errorCodes';
import { useSuspenseQuery } from '@tanstack/react-query';
import { fetchSimInventoryEntry } from 'simInventory/SimInventoryApi/simInventoryApi';
import { useSimDetailSubscription } from './useSimDetailSubscription';

export const useSimDetails = (iccid: string) => {
  const { data, refetch } = useSuspenseQuery({
    queryKey: ['SimInventoryEntry', iccid],
    queryFn: () => (!!iccid ? fetchSimInventoryEntry(iccid!) : null),
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 3;
    },
  });

  useSimDetailSubscription(iccid, ['SimInventoryEntry', iccid]);

  return { data, refetch };
};
