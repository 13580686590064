import { Chart } from 'react-chartjs-2';
import React, { useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
  CategoryScale,
  BarElement,
  TimeSeriesScale,
  Decimation,
  LineElement,
} from 'chart.js';

import zoom from 'chartjs-plugin-zoom';
import 'chartjs-adapter-luxon';
import Box from '@mui/material/Box';
import { GRAPH_DEFAULT_OPTIONS, chartBorderPlugin } from './graphConfig';
import { PeakChart } from './PeakChart';
import { middleLine } from './utils/graphPlugins';
import { useDispatchEventToChart } from './common/Graphs/hooks/useDispatchEventToChart';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  Tooltip,
  PointElement,
  BarElement,
  TimeSeriesScale,
  Decimation,
  LineElement,
  PeakChart,
);
ChartJS.register(zoom);

export type PoolingChartEntryData = {
  x: Date;
  y: string;
  color?: string;
};

export type RSimSessionGraphRangeProps = {
  entries: PoolingChartEntryData[];
  from: number;
  to: number;
  min: number;
  max: number;
  onZoomComplete: (range: [Date, Date]) => void;
  onPan?: (newMin: Date, newMax: Date) => void;
};

export const PoolingGraph: React.FC<RSimSessionGraphRangeProps> = React.memo(
  ({ entries, from, to, onZoomComplete, onPan, min, max }) => {
    const barOptions = useMemo(
      () => ({
        ...GRAPH_DEFAULT_OPTIONS,
        animation: false,
        parsing: false,
        scales: {
          x: {
            ...GRAPH_DEFAULT_OPTIONS!.scales!.x,
            type: 'time',
            min: new Date(from),
            max: new Date(to)
          },
          y: {
            display: false,
            beginAtZero: false,
            min: -1.0,
            max: 1.0
          }
        },
        plugins: {
          ...GRAPH_DEFAULT_OPTIONS!.plugins,
          tooltip: {
            enabled: false
          },
          decimation: {
            enabled: true,
            algorithm: 'lttb',
            samples: 2000
          },
          zoom: {
            ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom,
            limits: {
              ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.limits,
              x: {
                //@ts-ignore
                ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.limits.x,
                min: min,
                max: max
              }
            },
            zoom: {
              ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.zoom,
              //@ts-ignore
              onZoom(context) {
                onZoomComplete([
                  new Date(context.chart.scales.x.min),
                  new Date(context.chart.scales.x.max)
                ]);
              }
            },
            pan: {
              ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.pan,
              mode: 'x',
              scaleMode: 'x',

              threshold: 200,
              //@ts-ignore
              onPanStart: (event) => {},
              //@ts-ignore
              onPan: (event) => {
                if (onPan) {
                  // //@ts-ignore
                  const min = new Date(event.chart.boxes[0].min);
                  // // console.log(subMilliseconds())
                  //@ts-ignore
                  const max = new Date(event.chart.boxes[0].max);
                  // console.log(min, max);
                  onPan(min, max);
                }
              }
            }
          },
          middleLine: {
            borderColor: '#C0CACC',
            borderWidth: 1
          }
        }
      }),
      [from, to]
    );

    const data = useMemo(() => {
      const nacks = entries.filter((entry) => entry.color === '#E9A625');
      return {
        datasets: [
          {
            data: [...nacks],
            borderColor: '#E9A625',
            borderWidth: 0.2
          },
          {
            data: [...entries],
            borderWidth: 2
          }
        ]
      };
    }, [entries]);

    const chartRef = useRef();
    useDispatchEventToChart(chartRef);

    return (
      <Box sx={{ height: 100 }}>
        <Chart
          ref={chartRef}
          //@ts-ignore
          type="peak"
          //@ts-ignore
          options={barOptions}
          data={data}
          plugins={[chartBorderPlugin, middleLine]}
        />
      </Box>
    );
  }
);
