import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { closeBtnStyle, detailsGrid, dialogActions, dialogTitle } from './FiltersModal.styles';
import { useTranslation } from 'react-i18next';
import { scrollBarsSX } from 'theme/utility/utility.styles';

type FiltersDialogProps = {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onClear: () => void;
  onCancel: () => void;
  onApply: () => void;
  oneColumn?: boolean;
};

export const FiltersDialog: React.FC<FiltersDialogProps> = ({
  open,
  children,
  onClose,
  onClear,
  onCancel,
  onApply,
  oneColumn = false,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={!oneColumn ? 'lg' : 'md'}>
      <DialogTitle sx={dialogTitle}>
        <Typography variant="h2" component="div">
          {t('common.filters')}
        </Typography>
        <IconButton sx={closeBtnStyle} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, ...scrollBarsSX }} data-testid="filters dialog content">
        <Grid container sx={detailsGrid} alignItems="baseline" rowSpacing={8} columnSpacing={10}>
          {React.Children.map(children, (child) => {
            return <Grid size={{ xs: 12, md: oneColumn ? 12 : 6 }}>{child}</Grid>;
          })}
        </Grid>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button
          variant="text"
          color="tertiary"
          onClick={onClear}
          data-testid="Clear filters button"
        >
          {t('common.clearFilters')}
        </Button>
        <Button color="secondary" onClick={onCancel} data-testid="Cancel filters button">
          {t('common.cancel')}
        </Button>
        <Button color="primary" onClick={onApply} data-testid="Apply filters button">
          {t('common.applyFilters')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
