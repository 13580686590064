import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { activePageAtom, pageTabAtom } from 'atoms/pages';
import { useAtomValue } from 'jotai';
import React from 'react';
import { To, useNavigate } from 'react-router-dom';

export type NavigationLinkProps = {
  to: To;
  'data-testid'?: string;
  label: string;
  icon?: React.ReactNode;
  page: 'inventory' | 'provisioning' | 'admin';
};

const ListItemButton = styled(MuiListItemButton)(({ selected, theme }) => {
  return {
    borderRight: selected ? '4px solid ' : '',
    marginLeft: theme.spacing(1),
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    paddingLeft: theme.spacing(2),
    borderColor: theme.palette.grey[500],
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  };
});

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => {
  return {
    minWidth: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  };
});

export const NavigationLink = ({ page, label, icon, ...props }: NavigationLinkProps) => {
  const activePage = useAtomValue(activePageAtom);
  const navigationPage = useAtomValue(pageTabAtom(page));

  const activeTabName = navigationPage.activeTab;
  const tabs = navigationPage.tabs;

  const activeTab = tabs.find((tab) => tab.name === activeTabName);

  const navigate = useNavigate();

  return (
    <ListItem
      disablePadding
      {...props}
      onClick={() => {
        if (activeTab?.pathname) {
          navigate(activeTab?.pathname);
        }
      }}
    >
      <ListItemButton selected={activePage === page}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography sx={{ typography: 'h4' }} primary={label} />
      </ListItemButton>
    </ListItem>
  );
};
