import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useInfinityRSimSessionHistory } from '../../hooks/useInfinityRSimSessionHistory';
import { useSuspenseQuery } from '@tanstack/react-query';
import { fetchRSimInventory } from '../../RSimApi/rSimApi';
import { ErrorCodes } from '../../../common/errorCodes';
import { fetchSimInventoryEntry } from '../../../simInventory/SimInventoryApi/simInventoryApi';
import Box from '@mui/material/Box';
import { ScrollArea } from '../../../common/components/ScrollArea';
import { SessionHistoryHeaderRow } from '../../../simInventory/SessionHistory/Components/SessionHistoryHeaderRow';
import { SessionHistoryRowWithInterim } from '../../../simInventory/SessionHistory/Components/SessionHistoryRowWithInterim';
import CircularProgress from '@mui/material/CircularProgress';
import { EmptyTableMessage } from '../../../common/components/EmptyTableMessage';
import { ImsiNotValid } from './erros/ImsiNotValid';

export const RSimSessionHistoryTable = () => {
  const { t } = useTranslation();
  const { euicc } = useParams();

  const { data: rSimInventory } = useSuspenseQuery({
    queryKey: ['rSimInventory', euicc],
    queryFn: () => (!!euicc ? fetchRSimInventory(0, 1, '', euicc) : null),
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const rSim = rSimInventory?.items[0];

  const { data: primarySim } = useSuspenseQuery({
    queryKey: ['rSimInventory', rSim?.primaryIccid],
    queryFn: () => (!!rSim?.primaryIccid ? fetchSimInventoryEntry(rSim?.primaryIccid!) : null),
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }

      return failureCount < 3;
    },
  });

  const primarySimImsi = primarySim?.imsi;

  if (!primarySimImsi) {
    throw new ImsiNotValid();
  }

  const { sessionHistory, showLoader, refToLoader, fetchingEnded } =
    useInfinityRSimSessionHistory(euicc);

  const getOpenedRowsMap = () => {
    return (
      sessionHistory.reduce((current, item) => {
        return { ...current, [item.id]: false };
      }, {}) || {}
    );
  };
  const [openedRows, setOpenedRows] = useState<Record<string, boolean>>(getOpenedRowsMap());

  const collapseAllButtonVisible = Object.values(openedRows).some((opened) => opened);

  return (
    <Box
      sx={{ height: '100%', backgroundColor: sessionHistory.length === 0 ? 'white' : '#F9F9F9' }}
    >
      <ScrollArea>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '48px repeat(14, max-content) auto',
          }}
        >
          <SessionHistoryHeaderRow
            collapseAllButtonVisible={collapseAllButtonVisible}
            onCollapseAllClick={() => setOpenedRows(getOpenedRowsMap())}
            showProfile={true}
          />
          <Box
            sx={{
              display: 'grid',
              gridColumn: 'span 17',
              gridTemplateColumns: 'subgrid',
              borderBottom: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              '&>*': {
                borderLeft: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
                borderRight: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              },
              '&>div:first-child': {
                borderTop: 'none',
              },
              '&>.opened': {
                borderBottom: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              },
              '&>* + .opened': {
                mt: 3,
              },
              '&>*:has(+  .opened)': {
                borderBottom: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              },
              '&>.opened + *': {
                mt: 3,
              },
              '&>*+*': {
                borderTop: (theme) => `1px solid ${theme.palette.tableBorder.main}`,
              },
            }}
          >
            {sessionHistory.map((row) => {
              return (
                <SessionHistoryRowWithInterim
                  {...row}
                  imsi={row.imsi}
                  opened={openedRows?.[row.id] || false}
                  onToggle={(open: boolean) => {
                    setOpenedRows((currentVal) => ({ ...currentVal, [row.id]: open }));
                  }}
                  showProfile={true}
                  isPrimary={row.imsi + '' === primarySimImsi}
                />
              );
            })}
            {showLoader && (
              <Box
                sx={{ py: 2, gridColumn: 'span 17', background: (theme) => theme.palette.white }}
              >
                <div ref={refToLoader}>
                  <Box
                    sx={{
                      position: 'sticky',
                      left: '50vw',
                      width: 'min-content',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                </div>
              </Box>
            )}
          </Box>
          {sessionHistory.length === 0 && fetchingEnded ? (
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                width: 'min-content',
                transform: 'translateX(-50%) translateY(-50%)',
              }}
            >
              <EmptyTableMessage message={t('common.noResults')} />
            </Box>
          ) : null}
        </Box>
      </ScrollArea>
    </Box>
  );
};
