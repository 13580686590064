import { useLocation, useSearchParams } from 'react-router-dom';

import {
  FilterSimCardIdentifier,
  LastActiveProfileFilter,
  LastUpdateTimeFilters,
  RSIM_INITIAL_FILTERS,
  RSimFilters,
  SimulateOutageFilter,
  ValidRSimFiltersKeys,
} from '../data/filters.constants';
import { useCallback, useContext, useEffect, useState } from 'react';
import { usePassBackState } from 'common/hooks/usePassBackState';
import { LocationStoreContext } from 'common/components/LocationStorage/LocationStateStoreProvider';

import { cleanDefaultValuesURLSearchParams } from 'common/utils/urlSearchParamsUtils';

const filterDefaultFiltersValue = (filters: RSimFilters) =>
  cleanDefaultValuesURLSearchParams(filters, RSIM_INITIAL_FILTERS);

export const useRSimFiltersParams = () => {
  const stateBack = usePassBackState();
  const { saveCurrentLocationState } = useContext(LocationStoreContext);
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState(
    stateBack?.searchText?.at(-1) || RSIM_INITIAL_FILTERS.searchText,
  );

  useEffect(() => {
    saveCurrentLocationState({ searchText: [searchQuery] });
  }, [location.key]);

  const [filters, setFilters] = useSearchParams({});

  const updateAll = useCallback(
    (filters: RSimFilters) => {
      const { searchText, ...otherFilters } = filters;
      setSearchQuery(searchText);
      const newFilters = filterDefaultFiltersValue({ searchText: '', ...otherFilters });
      setFilters(newFilters, { replace: true });
    },
    [setSearchQuery, setFilters],
  );

  const updateOne = useCallback(
    (name: ValidRSimFiltersKeys, value: string) => {
      if (name === 'searchText') {
        setSearchQuery(value);
      } else {
        //@ts-ignore
        const newFilters = filterDefaultFiltersValue({
          ...Object.fromEntries(filters.entries()),
          [name]: value,
        });

        setFilters(newFilters);
      }
    },
    [filters, setSearchQuery, setFilters],
  );

  const clearAll = useCallback(() => {
    setFilters({});
  }, [setSearchQuery, setFilters]);

  const clearOne = useCallback(
    (name: ValidRSimFiltersKeys) => {
      updateOne(name, RSIM_INITIAL_FILTERS[name]);
    },
    [updateOne, setSearchQuery, setFilters],
  );

  const clearMany = useCallback(
    (names: ValidRSimFiltersKeys[]) => {
      const filtersToClear = names.reduce((filters, filterName: ValidRSimFiltersKeys) => {
        return {
          ...filters,
          [filterName]: RSIM_INITIAL_FILTERS[filterName],
        };
      }, {} as Record<ValidRSimFiltersKeys, string>);
      //@ts-ignore
      const newFilters = filterDefaultFiltersValue({
        ...Object.fromEntries(filters.entries()),
        ...filtersToClear,
      });
      setFilters(newFilters);
    },
    [filters, setSearchQuery, setFilters],
  );

  const searchText = searchQuery;

  const chosenIdType = (filters.get('chosenIdType') ||
    RSIM_INITIAL_FILTERS.chosenIdType) as FilterSimCardIdentifier;

  const lastActiveProfile = (filters.get('lastActiveProfile') ||
    RSIM_INITIAL_FILTERS.lastActiveProfile) as LastActiveProfileFilter;

  const lastUpdateTime = (filters.get('lastUpdateTime') ||
    RSIM_INITIAL_FILTERS.lastUpdateTime) as LastUpdateTimeFilters;

  const tags = filters.get('tags') || RSIM_INITIAL_FILTERS.tags;

  const connectionId = filters.get('connectionId') || RSIM_INITIAL_FILTERS.connectionId;
  const orderNumber = filters.get('orderNumber') || RSIM_INITIAL_FILTERS.orderNumber;

  const simulateOutage = (filters.get('simulateOutage') ||
    RSIM_INITIAL_FILTERS.simulateOutage) as SimulateOutageFilter;

  const accounts = filters.get('accounts') || RSIM_INITIAL_FILTERS.accounts;

  const eidFrom = filters.get('eidFrom') || RSIM_INITIAL_FILTERS.eidFrom;
  const eidTo = filters.get('eidTo') || RSIM_INITIAL_FILTERS.eidTo;

  return {
    filters: {
      searchText,
      chosenIdType,
      lastActiveProfile,
      lastUpdateTime,
      tags,
      connectionId,
      orderNumber,
      simulateOutage,
      accounts,
      eidFrom,
      eidTo,
    },
    updateAll,
    updateOne,
    clearAll,
    clearOne,
    clearMany,
  };
};
