import MuiSelect from '@mui/material/Select';
import { MultiSelectProps } from '../input.interface';
import { styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { CheckboxStyled } from '../../CheckboxStyled/CheckboxStyled';
import Typography from '@mui/material/Typography';
import { borderColor } from '../../../../theme/palette';
import { isArray } from 'lodash';

export const SelectInput = styled(MuiSelect, {
  name: 'Select',
  slot: 'input',
  overridesResolver: (props, styles) => styles.input,
})({});

export type SelectProps = MultiSelectProps & {
  showSelectAll: boolean;
  options: { label: string; value: string }[];
  value: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
};

const PaperSx = {
  '& .MuiCheckbox-root': {
    padding: '2px 6px 2px 2px',
  },
  '& .selectAll': {
    borderBottom: `1px solid ${borderColor}`,
  },
  '& li': {
    padding: '6px 4px',
  },
};

export const MultiSelect: React.FC<SelectProps> = ({
  onChange,
  value,
  showSelectAll,
  options,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <SelectInput
      multiple
      value={value}
      displayEmpty={true}
      onChange={(event) => {
        const val = event.target.value;
        //@ts-ignore
        if (showSelectAll && val[val.length - 1] === 'all') {
          if (options.length === value.length) {
            onChange([]);
          } else {
            onChange(options.map((o) => o.value));
          }
          return;
        }
        //@ts-ignore
        onChange(val);
      }}
      renderValue={(selected) => {
        if (isArray(selected) && selected.length === 0) {
          return (
            <Typography variant="text1" color="text.secondary">
              {placeholder}
            </Typography>
          );
        }
        const labels = options
          //@ts-ignore
          .filter((o) => selected.some((s) => o.value === s))
          .map((f) => f.label);
        return labels.join(', ');
      }}
      MenuProps={{
        PaperProps: {
          sx: PaperSx,
        },
      }}
      {...props}
    >
      {showSelectAll && (
        <MenuItem value="all" className="selectAll">
          <CheckboxStyled
            isChecked={value.length === options.length}
            handleCheckboxClick={() => {}}
            testId={'checkbox-all'}
          />
          <Typography variant="text1" color="text.primary">
            {t('common.selectAll')}
          </Typography>
        </MenuItem>
      )}
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <CheckboxStyled
            isChecked={value.indexOf(option.value) > -1}
            handleCheckboxClick={() => {}}
            testId={`checkbox-${option.value}`}
          />
          <Typography variant="text1" color="text.primary">
            {option.label}
          </Typography>
        </MenuItem>
      ))}
    </SelectInput>
  );
};
