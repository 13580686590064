import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { NoRowsOverlay } from './Components/NoRowsOverlay';
import { CellConfiguration } from './Components/Cells/CellLoader';
import React, { useMemo } from 'react';
import { DataGridProProps, GridPaginationModel } from '@mui/x-data-grid-pro';
import { Pagination } from './Components/Pagination/Pagination';
import { SkeletonLoader } from './Components/Loaders/SkeletonLoader';
import { ColumnHeaders } from './Components/Rows/ColumnHeaders';
import { GridRow } from './Components/Rows/GridRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material';
import { SortingIndicator } from './Components/Buttons/SortIndicator';
import { usePrepareConfiguration, Configuration } from './hooks/usePrepareConfiguration';
import { useDataGridDialogs } from './hooks/useDataGridDialogs';

type ConfigurableTableProps = {
  disablePagination?: boolean;
  disableColumnReorder?: boolean;
  configuration: Configuration[];
  cellConfiguration?: CellConfiguration;
  cellProps?: any;
  //TODO: Imporve typings (generic)
  rows?: Record<string, any>[];
  totalNumberOfItems?: number;
  actions?: any;
  'data-testid'?: string;
  page?: number;
  pageSize?: number;
  onPaginationChange?: (pagination: GridPaginationModel) => void;
  slots?: DataGridProProps['slots'];
  getDetailPanelContent?: DataGridProProps['getDetailPanelContent'];
  getDetailPanelHeight?: DataGridProProps['getDetailPanelHeight'];

  loading?: DataGridProProps['loading'];
  getRowId?: DataGridProProps['getRowId'];
  onSortModelChange?: DataGridProProps['onSortModelChange'];
  initialState?: DataGridProProps['initialState'];
};

//To remove when use this component in SimInventory
const CustomizeMuiDataGrid = styled(DataGridPro)<{
  displayDetailsPanel?: boolean;
  bottomContainerHeight?: number;
}>(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderTop: 'none',
  },

  '& .MuiDataGrid-columnHeader': {
    '&:focus': {
      outline: 'none !important',
    },
    '&:focus-within': {
      outline: 'none !important',
    },
  },
  '& .MuiDataGrid-filler--pinnedRight': {
    borderTop: 'none !important',
  },
  '& .MuiDataGrid-scrollbarFiller--borderTop': {
    borderTop: 'none !important',
  },
  '& .MuiDataGrid-detailPanel': {
    borderBottom: `1px solid ${theme.palette.tableBorder.main}`,
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    order: 2,
  },
  '& .MuiDataGrid-filler': {
    display: 'none',
  },
}));

const CustumizedKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)({ fontSize: 24 });
const CustumizedKeyboardArrowUpIcon = styled(KeyboardArrowUpIcon)({ fontSize: 24 });
export const ConfigurableTable = ({
  disablePagination,
  configuration,
  cellConfiguration,
  cellProps,
  rows = [],
  totalNumberOfItems,
  actions,
  page = 0,
  pageSize = 25,
  onPaginationChange,
  slots,
  ...props
}: ConfigurableTableProps) => {
  const { dialogs, selectDialog, unselectDialog, dialogActions } = useDataGridDialogs({
    actions,
  });

  const columnsConfiguration = usePrepareConfiguration({
    detailPanelEnabled: props.getDetailPanelContent !== undefined,
    configuration,
    cellConfiguration,
    cellProps,
    actions,
    selectDialog,
  });

  const paginationModel = useMemo(() => {
    return {
      page: page,
      pageSize: pageSize,
    };
  }, [page, pageSize]);

  return (
    <>
      <CustomizeMuiDataGrid
        {...props}
        sortingMode="server"
        sortingOrder={['desc', 'asc']}
        displayDetailsPanel={!!props.getDetailPanelContent}
        hideFooter={disablePagination}
        pagination={!disablePagination}
        disableColumnMenu
        columns={columnsConfiguration}
        columnBufferPx={999999}
        rowBufferPx={420}
        rowCount={totalNumberOfItems}
        onPaginationModelChange={onPaginationChange}
        paginationModel={disablePagination ? undefined : paginationModel}
        columnHeaderHeight={props.loading ? 0 : 42}
        rows={rows}
        slots={{
          columnHeaderSortIcon: SortingIndicator,
          noRowsOverlay: NoRowsOverlay,
          pagination: Pagination,
          loadingOverlay: () => <SkeletonLoader numberOfRows={2} />,
          columnHeaders: ColumnHeaders,
          row: GridRow,
          detailPanelExpandIcon: CustumizedKeyboardArrowDownIcon,
          detailPanelCollapseIcon: CustumizedKeyboardArrowUpIcon,
          ...slots,
        }}
        paginationMode={disablePagination ? undefined : 'server'}
        pinnedColumns={{
          right: ['actions'],
        }}
      />
      {dialogActions.map((action: any) => {
        return (
          <React.Fragment key={action.id}>
            <action.Component
              open={!!dialogs[action.id]}
              data={dialogs[action.id]}
              onClose={() => {
                unselectDialog(action.id);
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
