import CircleIcon from '@mui/icons-material/Circle';
import MuiChip from '@mui/material/Chip/Chip';
import styled from '@emotion/styled';
import { palette, statusColor, typoColor } from '../../../theme/palette';
import { ReactElement } from 'react';

export const BADGE_STATUSES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  PENDING: 'pending',
  INACTIVE: 'inactive',
  UNKNOWN: 'unknown',
} as const;

export const BADGE_TYPE = {
  FILLED: 'filled',
  TEXT: 'text',
} as const;

export const statusEnum = Object.values(BADGE_STATUSES);

export const typeEnum = ['filled', 'text'] as const;

export type BadgeStatus = (typeof statusEnum)[number];

type StatusBadgeProps = {
  status: BadgeStatus;
  type: (typeof typeEnum)[number];
  label?: string;
  icon?: ReactElement;
};

const colorMap = {
  success: {
    backgroundColor: palette.success.main,
    color: palette.success.contrastText,
    '& .MuiChip-icon': {
      color: palette.success.dark,
    },
  },
  warning: {
    backgroundColor: statusColor.warning,

    '& .MuiChip-icon': {
      color: '#ECAD33',
    },
  },
  error: {
    backgroundColor: palette.failed.main,
    color: palette.failed.contrastText,
    '& .MuiChip-icon': {
      color: palette.failed.dark,
    },
  },
  pending: {
    backgroundColor: palette.info.main,
    color: palette.info.contrastText,
    '& .MuiChip-icon': {
      color: palette.info.dark,
    },
  },
  inactive: {
    backgroundColor: palette.background.default,
    color: typoColor.secondary,
    '& .MuiChip-icon': {
      color: typoColor.tertiary,
    },
  },
  unknown: {
    backgroundColor: palette.background.default,
    color: typoColor.secondary,
    '& .MuiChip-icon': {
      color: palette.grey['100'],
      border: `1px solid ${typoColor.tertiary}`,
      borderRadius: 10,
      marginTop: '-2px',
    },
  },
};

const StyledChip = styled(MuiChip)(({ status }: StatusBadgeProps) => {
  if (statusEnum.includes(status)) {
    // @ts-ignore
    return {
      height: '28px',
      ...colorMap[status],
      '& .MuiChip-icon': {
        marginRight: '4px',
        marginLeft: 0,
        ...colorMap[status]?.['& .MuiChip-icon'],
      },
    };
  } else {
    return {};
  }
});

export const StatusBadge = ({ status, type = 'filled', label, icon }: StatusBadgeProps) => {
  return (
    <>
      <StyledChip
        sx={{
          ...(status && !label ? { textTransform: 'capitalize' } : {}),
          ...(type === 'text' ? { border: 'none', backgroundColor: 'transparent!important' } : {}),
        }}
        icon={
          icon || (
            <CircleIcon
              sx={{
                fontSize: '10px',
                marginLeft: '0px!important',
                marginTop: '-1px',
                marginRight: '4px!important',
              }}
            />
          )
        }
        label={label ? label : status}
        type={type}
        status={status}
      />
    </>
  );
};
