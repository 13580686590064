import * as React from 'react';
import { useMemo, useState } from 'react';
import { ReactComponent as ErrorTagIcon } from 'assets/images/ErrorTagIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/inputClose.svg';
import { ReactComponent as PreviousIcon } from 'assets/images/previous.svg';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip } from 'common/components/Chips/Chip';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Tooltip } from '../../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { palette } from 'theme/palette';

const isNullOrWhitespace = (input: string | null | undefined) => {
  return !input || !input.trim();
};

export const filterArray = (options: Option[]) => {
  return options.reduce((p: Option[], c: Option) => {
    if (!p.some((el: Option) => el.id === c.id)) {
      p.push(c);
    }
    return p;
  }, []);
};

const filterOptions = (options: Option[], inputValue: string, allowFilteringById: boolean) => {
  const itemsFilteredByName = options.filter((o: Option) =>
    o.name.toLowerCase().includes(inputValue.toLowerCase()),
  );
  let itemsFilteredById: Option[] = [];
  if (allowFilteringById) {
    itemsFilteredById = options.filter((o: Option) =>
      o.id.toLowerCase().includes(inputValue.toLowerCase()),
    );
  }
  return filterArray([...itemsFilteredByName, ...itemsFilteredById]);
};

const filterOptionsWithWarnings = (values: string[], options: Option[]) => {
  return [...options].map((option) => {
    if (values.includes(option.id)) {
      return option;
    } else {
      return { ...option, unselectable: true };
    }
  });
};

const icon = (
  <CheckBoxOutlineBlankIcon
    fontSize="small"
    sx={{ color: (theme) => theme.palette.text.secondary }}
  />
);
const checkedIcon = (
  <CheckBoxIcon fontSize="small" sx={{ color: (theme) => theme.palette.text.secondary }} />
);

const chipBoxSx = {
  '& .MuiChip-label': {
    maxWidth: '260px',
  },
};

export type Option = {
  name: string;
  id: string;
  toAdd?: boolean;
  unselectable?: boolean;
  error?: boolean;
  showBothIdAndName?: boolean;
  hint?: boolean;
  loading?: boolean;
};

export type AutocompleteSelectProps = {
  values: string[];
  options: Option[];
  placeholder?: string;
  allowAdding?: boolean;
  errors?: string[];
  warnings?: string[];
  onChange: (values: Option[]) => void;
  onAdd?: (values: string[]) => void;
  onTextInputChange?: (value: string) => void;
  inputValue?: string;
  allowFilteringById?: boolean;
  hints?: Option[];
  onRemoveHint?: (hint: Option) => void;
  testId?: string;
  isLoading?: boolean;
  isOpenWhenSelecting?: boolean;
};

export const AutocompleteSelect: React.FC<AutocompleteSelectProps> = ({
  options,
  values,
  placeholder,
  allowAdding = true,
  errors = [],
  warnings = [],
  inputValue,
  onChange,
  onAdd,
  onTextInputChange,
  allowFilteringById = false,
  hints = [],
  onRemoveHint,
  testId,
  isLoading = false,
  isOpenWhenSelecting = false,
}) => {
  const errorOptions = useMemo(() => {
    const errorOptions = [...errors, ...warnings].map((error) => ({
      name: error,
      id: uuidv4(),
      toAdd: false,
      unselectable: true,
      error: true,
    }));

    return errorOptions;
  }, [errors, warnings]);

  const hintOptions: Option[] = [...hints].reverse().map((hint) => ({
    name: hint.name,
    id: hint.id,
    toAdd: false,
    hint: true,
    showBothIdAndName: options[0]?.showBothIdAndName,
  }));

  const [open, setOpen] = useState(false);
  const hasErrors = errors.length > 0;

  const { t } = useTranslation();

  return (
    <Autocomplete
      data-testid={testId ?? 'multiselect'}
      multiple
      onChange={(_event, values) => {
        const newValueAdded = values.filter((value) => value.toAdd);
        const updatedValues = values.filter((value) => !value.toAdd);
        const removeUnselectable = updatedValues.filter((value) => !value.unselectable);

        onChange(removeUnselectable);
        onTextInputChange && onTextInputChange('');

        if (newValueAdded.length > 0 && onAdd) {
          onAdd(newValueAdded.map((option) => option.name));
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (value.startsWith(' ')) {
          value = value.trimStart();
        }

        if (reason !== 'reset') {
          onTextInputChange && onTextInputChange(value);
        }
      }}
      isOptionEqualToValue={(option, testOption) => {
        return option.id === testOption.id;
      }}
      options={options}
      value={options.filter((option) => {
        return values.indexOf(option.id) >= 0;
      })}
      selectOnFocus
      onOpen={() => {
        setOpen(true);
      }}
      onClose={(event) => {
        if (
          !hasErrors &&
          ((isOpenWhenSelecting && event.type === 'blur') || !isOpenWhenSelecting)
        ) {
          setOpen(false);
        }
      }}
      open={open || hasErrors}
      getOptionLabel={(option) => option.name}
      filterOptions={(options, params) => {
        const filteredOptionsAndHints = filterArray([
          ...filterOptions(hintOptions, params.inputValue, allowFilteringById),
          ...filterOptions(options, params.inputValue, allowFilteringById),
        ]);

        const filtered = [
          ...errorOptions,
          ...(warnings.length > 0
            ? filterOptionsWithWarnings(values, filteredOptionsAndHints)
            : filteredOptionsAndHints),
        ];

        if (!allowAdding) {
          if (isLoading) {
            filtered.push({
              name: `${t('common.loading')}...`,
              id: uuidv4(),
              toAdd: false,
              unselectable: true,
              error: false,
              loading: true,
            });
          }
          return filtered;
        }

        const { inputValue } = params;

        const isExisting = options.some((option) => inputValue === option.name);

        if (inputValue !== '' && !isExisting && !isNullOrWhitespace(inputValue) && !hasErrors) {
          filtered.push({ name: `${inputValue}`, toAdd: true, id: uuidv4(), unselectable: true });
        }

        return filtered;
      }}
      renderOption={({ className, ...props }, option, { selected }) => {
        return (
          <Box
            component="li"
            {...props}
            sx={{
              width: 'inherit',
              pl: 0,
              pr: 1,
              pb: 2,
              pt: 0,
              height: '36px',
              backgroundColor: ({ palette }) =>
                selected ? palette.grey[200] : palette.background.paper,
              '.close-icon': { display: 'none', width: '24px', height: '24px' },
              '.hint-icon': { display: 'block', width: '24px', height: '24px' },
              '&:hover': {
                backgroundColor: ({ palette }) => palette.grey[100],
                '.close-icon': {
                  display: 'flex',
                  borderRadius: '27px',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                '.hint-icon': { display: 'none' },
              },
              pointerEvents: option.error ? 'none' : 'initial',
            }}
          >
            <Stack direction="row" alignItems="center" alignContent="center" spacing={2}>
              {!option.unselectable ? (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                  sx={{
                    padding: '6px 8px',
                    '& svg': { fontSize: '1.5em' },
                  }}
                />
              ) : null}
              {option.toAdd && option.name ? (
                <AddBoxIcon
                  sx={{ pt: 1, mt: '4px !important', ml: '8px !important', color: '#717676' }}
                />
              ) : null}
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%', ml: '4px !important' }}
                data-testid="multiselect-option"
              >
                <Box
                  sx={{
                    pt: option.unselectable ? 2 : 0,
                    pl: option.toAdd || option.loading ? 2 : 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                    // for "toAdd": 8px padding + 38px icon + 150px for 'confirm to add...' text
                    // for "hint": 8px padding + 38px icon + 20px for hint icon
                    // for normal option: 8px padding + 38px icon
                    maxWidth: option.toAdd
                      ? 'calc(100% - 196px)'
                      : option.hint
                      ? 'calc(100% - 66px)'
                      : 'calc(100% - 46px)',
                  }}
                >
                  <Typography
                    variant="text1"
                    color={
                      option.error
                        ? 'failed.contrastText'
                        : option.loading
                        ? 'text.secondary'
                        : 'text.primary'
                    }
                  >
                    {option.name}
                  </Typography>
                  {option.showBothIdAndName && (
                    <Typography variant="text1" color="text.secondary">
                      {', '} {option.id}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ pt: option.unselectable ? 2 : 0 }}>
                  <Typography
                    variant="text1"
                    color={option.error ? 'failed.contrastText' : 'text.primary'}
                  >
                    {option.toAdd && option.name ? ' (confirm to add a new tag)' : ''}
                  </Typography>
                </Box>
              </Stack>
              {option.error ? (
                <Box sx={{ height: '24px', mr: 1 }}>
                  <ErrorTagIcon />
                </Box>
              ) : null}
              {option.hint ? (
                <Box
                  sx={{
                    height: '24px',
                    cursor: 'pointer',
                    mr: 1,
                    ml: '-20px !important',
                    position: 'absolute',
                    right: 0,
                  }}
                  data-testid="remove-hint-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveHint && onRemoveHint(option);
                  }}
                >
                  <PreviousIcon
                    data-testid="hint-icon"
                    className="hint-icon"
                    width="21px"
                    height="18px"
                  />

                  <Tooltip title={t('common.clear')} showArrow={true} variant="dark">
                    <Box data-testid="remove-hint-icon" className="close-icon">
                      <CloseIcon width="12px" height="12px" role="img" />
                    </Box>
                  </Tooltip>
                </Box>
              ) : null}
              {option.loading ? (
                <Box
                  sx={{
                    height: '24px',
                    mr: 1,
                    pt: 0.75,
                    '& span': {
                      color: palette.tertiary.dark,
                    },
                  }}
                >
                  <CircularProgress size="18px" />
                </Box>
              ) : null}
            </Stack>
          </Box>
        );
      }}
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        '.MuiAutocomplete-inputRoot': {
          py: 1.25,
          pl: 3,
          height: 'auto',
          minHeight: '40px',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderRadius: 3,
        },
      }}
      ListboxProps={{
        className: 'autocomplete-list-box',
      }}
      PaperComponent={(params) => {
        return (
          <Paper
            {...params}
            sx={{
              borderRadius: 4,
              padding: '8px 4px 8px 8px',
              boxShadow: '0px 0px 12px 0px #0000001F',
              '.MuiAutocomplete-listbox': {
                p: 0,
                borderRadius: 4,
              },
              '.autocomplete-list-box': {
                overflowX: 'clip',
              },
              '.autocomplete-list-box::-webkit-scrollbar': {
                width: '12px',
                transition: '0.2s',
              },
              '.autocomplete-list-box::-webkit-scrollbar-thumb': {
                borderRadius: '8px',
                backgroundColor: '#d9dbdd',
                border: '2px solid transparent',
                backgroundClip: 'padding-box',
              },
              '.autocomplete-list-box::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#B6BABA',
                border: 0,
              },
            }}
          />
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              sx: { p: '0 !important' },
            }}
            placeholder={values.length > 0 ? '' : placeholder}
          />
        );
      }}
      renderTags={(selectedOptions, getTagsProps) => {
        return selectedOptions.map((option, index) => {
          const { onDelete, ...tagProps } = getTagsProps({ index });
          return (
            <Box {...tagProps} sx={chipBoxSx}>
              <Chip label={option.name} variant="outlined" onDelete={onDelete} />
            </Box>
          );
        });
      }}
    />
  );
};
