import { DEFAULT_BORDER_RADIUS } from 'theme/constants';
import { Components } from '@mui/material';
import { greyColor, typoColor } from 'theme/palette';
import { typography } from 'theme/typography';

export const MuiSelect: Components['MuiSelect'] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      let additionalStyle = {};
      if (ownerState.variant === 'filled') {
        additionalStyle = {
          backgroundColor: 'white',
          '&::before, &::after, &:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none',
          },
          '& fieldset': {
            border: 0,
            borderColor: 'white',
          },
        };
      }

      return {
        borderRadius: DEFAULT_BORDER_RADIUS,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: typoColor.tertiary,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: typoColor.secondary,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: greyColor[600],
        },
        ...additionalStyle,
      };
    },
    iconFilled: {
      right: 'unset',
      left: '7px',
    },
    // @ts-ignore
    filled: {
      borderRadius: DEFAULT_BORDER_RADIUS,
      padding: '12px 16px 10px 36px !important',
      boxShadow: '0px 0px 5px 0px #00000014, 0px 1px 2px 0px #00000014',
      color: typoColor.secondary,
      height: '40px',
      boxSizing: 'border-box',
      ...typography.button,
    },
    nativeInput: {
      ...typography.text1,
      color: typoColor.primary,
      '&::placeholder': {
        ...typography.text1,
        color: typoColor.secondary,
        opacity: 1,
      },
    },
    select: { paddingTop: '11px', paddingBottom: '11px' },
  },
};
