import { useLazyPaginationQuery } from 'common/useLazyPaginationQuery';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import {
  fetchSimInventoryExtendedFilters,
  fetchSimInventoryExtendedFiltersPagination,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import { SimInventoryData } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { useDebounce } from 'usehooks-ts';
import { Sorting } from 'common/hooks/useSorting';
import { SimInventoryFilters, SimProfileId } from 'simInventory/models/SimProfile.model';

export const useSimInventoryFetch = (
  searchIds: string[],
  chosenIdType: SimProfileId,
  filters: SimInventoryFilters,
  sort: { columnName: string; sort: Sorting } | null = null,
) => {
  const debouncedSearchText = useDebounce(searchIds.join() || '', 500);
  const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;
  const searchParams = {
    searchText: searchIds,
    chosenIdType,
    filters,
  };

  const { tags, ...otherFilters } = filters;

  const { queryResult, pagination, queryKey } = useLazyPaginationQuery<SimInventoryData>(
    [
      'SimInventoryConfigurable',
      minLengthText,
      chosenIdType,
      sort,
      ...Object.values(otherFilters),
      tags,
    ],
    async ({ signal, page, rowsPerPage }) => {
      return setMinimalPromiseTime(
        fetchSimInventoryExtendedFilters(page, rowsPerPage, searchParams, sort, signal),
      );
    },
    ({ signal, page, rowsPerPage }) =>
      fetchSimInventoryExtendedFiltersPagination(page, rowsPerPage, searchParams, signal),
    {
      refetchOnWindowFocus: false,
    },
  );

  const simInventory = queryResult.data;
  const isRefetching = queryResult.isRefetching;
  const isLoading = queryResult.isLoading;

  return {
    simInventory,
    pagination,
    queryKey,
    isRefetching,
    isLoading,
  };
};
