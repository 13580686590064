declare module '@mui/material/styles' {
  interface Palette {
    white: string;
    failed: Palette['primary'];
    tableBorder: Palette['primary'];
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    white: string;
    failed: PaletteOptions['primary'];
    tableBorder: PaletteOptions['primary'];
    tertiary: PaletteOptions['primary'];
  }

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    tertiary: string;
  }
}

export const backgroundColor = '#F9F9F9';

export const greyColor = {
  '100': '#F6FAFC',
  '200': '#EFF4F5',
  '300': '#DCE4E5',
  '400': '#C0CACC',
  '500': '#8D9B9E',
  '600': '#667C81',
  '700': '#597B82',
};

export const typoColor = {
  primary: '#2F3232',
  secondary: '#707676',
  tertiary: '#AAADAD',
  white: '#FFFFFF',
};

export const statusColor = {
  success: '#0AA849',
  success2: '#3E7747',
  error: '#DC0D1A',
  error2: '#DE3C46',
  warning: '#FCF5E6',
};

export const statusTextColor = {
  warning: '#E9A625',
};

export const borderColor = '#E5E5E5';
export const palette = {
  grey: greyColor,
  white: '#FFFFFF',
  background: {
    default: backgroundColor,
  },
  success: {
    main: '#E6F7E9',
    contrastText: statusColor.success2,
    dark: '#4B9256',
  },
  success2: {
    main: statusColor.success2,
    contrastText: typoColor.white,
  },
  failed: {
    main: '#FBE7E8',
    contrastText: '#B3261E',
    dark: '#D2535C',
  },
  failed2: {
    main: statusColor.error2,
    contrastText: typoColor.white,
  },
  tertiary: {
    main: typoColor.white,
    dark: typoColor.tertiary,
    contrastText: typoColor.primary,
  },
  tableBorder: {
    main: borderColor,
    dark: greyColor[200],
    light: backgroundColor,
    contrastText: typoColor.primary,
  },
  text: typoColor,
  info: {
    main: '#E6F1F7',
    contrastText: '#407695',
    dark: '#3791C5',
  },
  statusColor,
  statusTextColor,
};
