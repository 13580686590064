import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export const filterArray = (options: Option[]) => {
  return options.reduce((p: Option[], c: Option) => {
    if (!p.some((el: Option) => el.id === c.id)) {
      p.push(c);
    }
    return p;
  }, []);
};

const filterOptions = (options: Option[], inputValue: string) => {
  const itemsFilteredByName = options.filter((o: Option) =>
    o.label.toLowerCase().includes(inputValue.toLowerCase()),
  );
  return filterArray(itemsFilteredByName);
};

export type Option = {
  label: string;
  id: number | string;
};

export type SelectWithSearchProps = {
  value: Option | null;
  options: Option[];
  onChange: (value: Option | null) => void;
  onTextInputChange?: (value: string) => void;
  inputValue?: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  'data-testid'?: string;
};

export const SelectWithSearch: React.FC<SelectWithSearchProps> = ({
  options,
  value,
  inputValue,
  onChange,
  onTextInputChange,
  placeholder,
  sx,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      data-testid={props['data-testid'] ?? 'select-with-search'}
      onChange={(_event, value) => {
        onChange(value);
        onTextInputChange && onTextInputChange('');
      }}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (value.startsWith(' ')) {
          value = value.trimStart();
        }

        if (reason !== 'reset') {
          onTextInputChange && onTextInputChange(value);
        }
      }}
      isOptionEqualToValue={(option, testOption) => {
        return option.id === testOption.id;
      }}
      options={options}
      value={value}
      selectOnFocus
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, params) =>
        filterArray([...filterOptions(options, params.inputValue)])
      }
      renderOption={({ className, ...props }, option, { selected }) => {
        return (
          <Box
            component="li"
            {...props}
            sx={{
              width: 'inherit',
              pl: 1,
              pr: 1,
              pb: 2,
              pt: 1.5,
              height: '36px',
              backgroundColor: ({ palette }) =>
                selected ? palette.grey[200] : palette.background.paper,
              '.close-icon': { display: 'none', width: '24px', height: '24px' },
              '.hint-icon': { display: 'block', width: '24px', height: '24px' },
              '&:hover': {
                backgroundColor: ({ palette }) => palette.grey[100],
                '.close-icon': {
                  display: 'flex',
                  borderRadius: '27px',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                '.hint-icon': { display: 'none' },
              },
              pointerEvents: 'initial',
            }}
          >
            <Stack direction="row" alignItems="center" alignContent="center" spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%', ml: '4px !important' }}
                data-testid="multiselect-option"
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                    maxWidth: '100%',
                  }}
                >
                  <Typography variant="text1" color="text.primary">
                    {option.label}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
        );
      }}
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        '.MuiAutocomplete-inputRoot': {
          py: 1.25,
          pl: 3,
          height: 'auto',
          minHeight: '40px',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderRadius: 3,
        },
        ...sx,
      }}
      ListboxProps={{
        className: 'autocomplete-list-box',
      }}
      PaperComponent={(params) => {
        return (
          <Paper
            {...params}
            sx={{
              borderRadius: 4,
              padding: '8px 4px 8px 8px',
              boxShadow: '0px 0px 12px 0px #0000001F',
              '.MuiAutocomplete-listbox': {
                p: 0,
                borderRadius: 4,
              },
              overflowX: 'clip',
              '.autocomplete-list-box::-webkit-scrollbar': {
                width: '12px',
                transition: '0.2s',
              },
              '.autocomplete-list-box::-webkit-scrollbar-thumb': {
                borderRadius: '8px',
                backgroundColor: '#d9dbdd',
                border: '2px solid transparent',
                backgroundClip: 'padding-box',
              },
              '.autocomplete-list-box::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#B6BABA',
                border: 0,
              },
            }}
          />
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              sx: { p: '0 !important' },
            }}
          />
        );
      }}
    />
  );
};
