import { ConfirmationDialog } from 'common/components/Dialog/ConfirmationDialog';
import DownloadIcon from '@mui/icons-material/Download';
export type LargeExportPromptProps = {
  open: boolean;
  numberOfCards: number;
  onConfirm: () => void;
  onClose: () => void;
};

export const LargeExportPrompt = ({
  open,
  numberOfCards,
  onConfirm,
  onClose,
}: LargeExportPromptProps) => {
  return (
    <ConfirmationDialog
      open={open}
      titleIcon={<DownloadIcon />}
      title={'Sim inventory export'}
      message={
        <div data-testid="export-warning">
          We’ll process your request of <strong>{numberOfCards}</strong> SIMs export in the
          backgound.
          <div> You'll receive an email with a download link when it's ready.</div>
        </div>
      }
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  );
};
