import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { fetchRSimInventory, LastActiveProfile } from '../../RSimApi/rSimApi';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ErrorCodes } from 'common/errorCodes';
import { Suspense, useCallback, useMemo, useState } from 'react';
import {
  rSimInventoryDetails,
  rSimInventoryDetailsScrollArea,
  tagGridSizes,
} from './RSimInventoryDetailsPage.style';
import { ScrollArea } from 'common/components/ScrollArea';
import { ContentCopyExtension } from 'common/ContentCopyExtension/ContentCopyExtension';

import mapValues from 'lodash.mapvalues';
import { Button } from 'common/components/Buttons/Button';
import Stack from '@mui/material/Stack';
import { RSimInventoryEditDialog } from '../../RSimTags/RSimInventoryEditDialog';
import { TagDisplay } from 'tags/TagsDisplay';
import { Tag } from 'tags/services/tagsApi.types';
import { fetchSimInventoryEntry } from 'simInventory/SimInventoryApi/simInventoryApi';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { ValueBox } from 'common/components/Table/TwoValueCell';
import { ReactComponent as ConfigurationIcon } from 'assets/images/configuration.svg';
import { ReactComponent as PendingIcon } from 'assets/images/pending.svg';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import { RSimStatusIndicator } from '../RSimStatusIndicator';
import { KickRsimProvider } from '../KickRsimProvider';
import { RsimProfileStatus } from '../../RSimApi/rSimApi.interface';
import { RSimInventoryConfigurationDialog } from '../../RSimConfiguration/RSimInventoryConfigurationDialog';
import { AutoHideSnackbar } from 'common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from 'common/Snackbar/AlertTitle';
import { SectionGridRow } from '../../../common/components/SectionGrid/SectionGridRow';
import { SectionGridItem } from '../../../common/components/SectionGrid/SectionGridItem';
import { RSimInventorySimDetails } from './RSimInventorySimDetails';
import { RefetchQueryButton } from 'common/components/Queries/RefetchQueryButton';
import { isAfter } from 'date-fns';
import { getSessionStatusTranslatedLabel } from '../../../simInventory/utils/sessionStatus';
import { getHuminizeDurationInSecFunction } from 'i18n/dateLocale';
import { areLabelsExist } from '../../../common/utils/areLabelsExist';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';

export type SimInventoryEntryRoutingParams = {
  euicc: string;
};

const humanize = getHuminizeDurationInSecFunction();

export const RSimInventoryDetailsPage = () => {
  const { t } = useTranslation();
  const { euicc } = useParams<SimInventoryEntryRoutingParams>();

  const { data: rSimInventory, refetch: refetchRSimInventory } = useSuspenseQuery({
    queryKey: ['fetchRSimDetails', euicc],
    queryFn: () => (!!euicc ? fetchRSimInventory(0, 1, '', euicc) : null),
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const rSim = rSimInventory?.items[0];

  const { data: primarySim } = useSuspenseQuery({
    queryKey: ['fetchRSimDetailsPrimarySim', rSim?.primaryIccid],
    queryFn: () =>
      !!rSim?.primaryIccid ? fetchSimInventoryEntry(rSim?.primaryIccid!, true) : null,

    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const { data: secondarySim } = useSuspenseQuery({
    queryKey: ['fetchRSimDetailsSecondarySim', rSim?.secondaryIccid],
    queryFn: () =>
      !!rSim?.secondaryIccid ? fetchSimInventoryEntry(rSim?.secondaryIccid!, true) : null,
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const primarySimEntry = useMemo(
    () =>
      mapValues(primarySim, (value) => {
        if (value === '') {
          return '-';
        }

        return value;
      }),
    [primarySim],
  );

  const secondarySimEntry = useMemo(
    () =>
      mapValues(secondarySim, (value) => {
        if (value === '') {
          return '-';
        }

        return value;
      }),
    [secondarySim],
  );

  let lastActiveRsimProfile = null;
  if (rSim?.lastActiveProfile === LastActiveProfile.Primary) {
    lastActiveRsimProfile = primarySimEntry;
  }

  if (rSim?.lastActiveProfile === LastActiveProfile.Secondary) {
    lastActiveRsimProfile = secondarySimEntry;
  }

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [configurationDialogOpen, setConfigurationDialogOpen] = useState(false);

  const handleOnClose = useCallback(() => {
    setEditDialogOpen(false);
    setConfigurationDialogOpen(false);
  }, []);

  const handleTagsUpdate = useCallback(() => {
    refetchRSimInventory();
  }, [refetchRSimInventory]);

  const restoreConfiguration = async (isSuccessful: boolean) => {
    setRestoreStatus({ success: isSuccessful, error: !isSuccessful });
  };

  const [restoreStatus, setRestoreStatus] = useState<{ success: boolean; error: boolean }>({
    success: false,
    error: false,
  });

  let simWithLastSession = null;
  let simNameWithLastSession = null;
  if (
    primarySimEntry?.sessionStatusLastUpdated &&
    primarySimEntry?.sessionStatusLastUpdated !== '-' &&
    secondarySimEntry?.sessionStatusLastUpdated &&
    secondarySimEntry?.sessionStatusLastUpdated !== '-'
  ) {
    const isPrimaryTimestampAfterSecondary = isAfter(
      new Date(primarySimEntry.rawData.sessionStatusLastUpdated),
      new Date(secondarySimEntry.rawData.sessionStatusLastUpdated),
    );

    simWithLastSession = isPrimaryTimestampAfterSecondary ? primarySimEntry : secondarySimEntry;
    simNameWithLastSession = isPrimaryTimestampAfterSecondary ? 'Primary' : 'Secondary';
  } else if (primarySimEntry?.sessionStatusLastUpdated !== '-') {
    simWithLastSession = primarySimEntry;
    simNameWithLastSession = 'Primary';
  } else if (secondarySimEntry?.sessionStatusLastUpdated !== '-') {
    simWithLastSession = secondarySimEntry;
    simNameWithLastSession = 'Secondary';
  }

  const rSimProfilesVisibleKeys = [
    'status',
    'lastPollingTimestamp',
    'isCustomConfigurationDetected',
    'isPendingConfiguration',
    'appliedOtaConfigurationProfile',
    'euicc',
    'iccidOta',
  ];
  const rSimProfilesVisible = areLabelsExist(rSim as {}, rSimProfilesVisibleKeys);

  const deviceVisibleKeys = ['imei', 'currentIpAddress', 'apn'];
  const deviceVisible = areLabelsExist(
    simWithLastSession ?? (primarySimEntry || secondarySimEntry),
    deviceVisibleKeys,
  );

  const forcedDeviceVisibleValues =
    (simWithLastSession ??
      (areLabelsExist(primarySimEntry, deviceVisibleKeys) ||
        areLabelsExist(secondarySimEntry, deviceVisibleKeys))) &&
    '-';

  const orderVisibleKeys = ['connectionId', 'orderNumber', 'customerReference'];
  const orderVisible = areLabelsExist(rSim as {}, orderVisibleKeys);

  const clientVisibleKeys = ['accountName', 'accountNumber', 'customerCommsPlan'];
  const clientVisible = areLabelsExist(rSim as {}, clientVisibleKeys);

  const usageInCycleVisibleKeys = [
    'dataUsage',
    'inMinuteUsage',
    'inTextUsage',
    'outMinuteUsage',
    'outTextUsage',
  ];
  const usageInCycleVisible = areLabelsExist(rSim as {}, usageInCycleVisibleKeys);

  const lastSessionDetailsVisibleKeys = [
    'sessionStatus',
    'sessionStatusLastUpdated',
    'sessionStartTime',
    'sessionEndTime',
    'cumulativeTotalUsage',
    'apn',
    'localization',
    'ratType',
    'rasClient',
    'framedProtocol',
    'nasIpAddress',
    'serviceType',
    'userName',
  ];

  const lastSessionDetailsVisible = areLabelsExist(
    (simNameWithLastSession && simWithLastSession) ?? (primarySimEntry || secondarySimEntry),
    lastSessionDetailsVisibleKeys,
  );

  const forcedLastSessionDetailsVisible =
    ((simNameWithLastSession || simWithLastSession) ??
      (areLabelsExist(primarySimEntry, lastSessionDetailsVisibleKeys) ||
        areLabelsExist(secondarySimEntry, lastSessionDetailsVisibleKeys))) &&
    '-';

  return (
    <Stack sx={rSimInventoryDetailsScrollArea}>
      <Box flex={0} sx={{ display: 'flex', justifyContent: 'end', pt: 2, pr: 6, gap: 4 }}>
        {(rSim?.isCustomConfigurationDetected ||
          rSim?.appliedOtaConfigurationProfile ||
          rSim?.status !== RsimProfileStatus.Unknown) && (
          <Box
            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', mr: 4 }}
            onClick={() => {
              setConfigurationDialogOpen(true);
            }}
          >
            <InfoIcon
              width="20px"
              height="20px"
              role="img"
              data-testid="configuration-details-icon"
            />
            <Typography variant="h4" color="text.secondary" sx={{ ml: 1.5, mt: 1 }}>
              {t('rSimInventory.configurationDetails')}
            </Typography>
          </Box>
        )}
        <Box>
          <RefetchQueryButton
            queryKey={[
              'fetchRSimDetails',
              'fetchRSimDetailsPrimarySim',
              'fetchRSimDetailsSecondarySim',
            ]}
          />
        </Box>
        <SimInventoryCan I={Actions.edit} a={Subjects.tags}>
          <Button variant="secondary" onClick={() => setEditDialogOpen(true)}>
            Edit
          </Button>
        </SimInventoryCan>
      </Box>
      <Box flex={1}>
        <ScrollArea>
          <KickRsimProvider
            euiccs={[euicc!]}
            queryKey={[
              'fetchRSimDetails',
              'fetchRSimDetailsPrimarySim',
              'fetchRSimDetailsSecondarySim',
            ]}
          >
            <Box sx={rSimInventoryDetails}>
              <Grid container direction="column">
                <>
                  {rSimProfilesVisible ? (
                    <>
                      <SectionGridRow
                        hideTopBorder={true}
                        sx={{ pl: 3 }}
                        title={t('rSimInventory.title')}
                      >
                        <>
                          <SectionGridItem
                            label={t('rSimInventory.rsimStatus')}
                            testId="rsimStatus"
                          >
                            <RSimStatusIndicator
                              euicc={euicc!}
                              status={rSim?.status!}
                              hideTooltip={true}
                            />
                          </SectionGridItem>
                          <SectionGridItem
                            value={rSim?.lastPollingTimestamp}
                            label={t('rSimInventory.lastPolling')}
                            testId="lastPolling"
                          />
                          <SectionGridItem label={t('rSimInventory.configuration')}>
                            <>
                              <Box
                                sx={{ whiteSpace: 'nowrap', display: 'flex' }}
                                data-testid="configuration"
                              >
                                {rSim?.isCustomConfigurationDetected && (
                                  <Tooltip title={t('rSimInventory.customConfigurationDetected')}>
                                    <ConfigurationIcon
                                      width="16px"
                                      height="16px"
                                      role="img"
                                      data-testid="custom-configuration-icon"
                                    />
                                  </Tooltip>
                                )}

                                {rSim?.isPendingConfiguration && (
                                  <Tooltip title={t('rSimInventory.configurationChangePending')}>
                                    <PendingIcon
                                      width="18px"
                                      height="18px"
                                      role="img"
                                      data-testid="change-pending-icon"
                                    />
                                  </Tooltip>
                                )}
                                <ContentCopyExtension
                                  value={rSim?.appliedOtaConfigurationProfile || '-'}
                                >
                                  <Typography
                                    marginRight={
                                      rSim?.isCustomConfigurationDetected ||
                                      rSim?.isPendingConfiguration
                                        ? '5px'
                                        : ''
                                    }
                                    variant="text1"
                                    color="text.primary"
                                    data-testid="configuration-profile"
                                  >
                                    {rSim?.appliedOtaConfigurationProfile ||
                                    rSim?.isCustomConfigurationDetected
                                      ? rSim?.appliedOtaConfigurationProfile
                                      : '-'}
                                  </Typography>
                                </ContentCopyExtension>

                                <ValueBox
                                  variant="text5"
                                  color="text.secondary"
                                  text={rSim?.appliedOtaConfigurationVersion || ''}
                                  testId="configuration-version"
                                />
                              </Box>
                            </>
                          </SectionGridItem>
                          <SectionGridItem
                            value={rSim?.euicc}
                            label={t('common.eid')}
                            testId="euicc"
                          />
                          <SimInventoryCan I={Actions.read} a={Subjects.otaConfiguration}>
                            <SectionGridItem
                              value={rSim?.iccidOta}
                              label={t('rSimInventory.otaIccid')}
                              testId="otaIccid"
                            />
                          </SimInventoryCan>
                        </>
                      </SectionGridRow>

                      <SectionGridRow hideTopBorder={true} sx={{ pb: 3, pl: 3 }}>
                        <SectionGridItem {...tagGridSizes} label={t('common.tags')} testId="tags">
                          {!!rSim?.tags?.items && rSim?.tags?.items.length > 0 ? (
                            <TagDisplay
                              id={euicc!}
                              initialTags={rSim?.tags?.items.map((item: Tag) => item.name) || []}
                              hasMore={rSim?.tags?.hasMore || false}
                              shouldWrap={true}
                            />
                          ) : (
                            <span>-</span>
                          )}
                        </SectionGridItem>
                      </SectionGridRow>
                    </>
                  ) : null}
                  {deviceVisible ? (
                    <SectionGridRow title={t('simInventory.device')} data-testid="device">
                      <>
                        <SectionGridItem
                          value={simWithLastSession?.imei ?? forcedDeviceVisibleValues}
                          label={t('simInventory.detectedImei')}
                          testId="detectedImei"
                        />
                        <SectionGridItem
                          value={simWithLastSession?.currentIpAddress ?? forcedDeviceVisibleValues}
                          label={t('simInventory.currentIp')}
                          testId="currentIp"
                        />
                        <SectionGridItem
                          value={simWithLastSession?.apn ?? forcedDeviceVisibleValues}
                          label={t('simInventory.apn')}
                          testId="apn"
                        />
                      </>
                    </SectionGridRow>
                  ) : null}
                  {orderVisible ? (
                    <SectionGridRow title={t('simInventory.order')}>
                      <>
                        <SectionGridItem
                          value={rSim?.connectionId}
                          label={t('simInventory.connectionId')}
                          testId="connectionId"
                        />
                        <SectionGridItem
                          value={rSim?.orderNumber}
                          label={t('simInventory.orderNumber')}
                          testId="orderNumber"
                        />
                        <SectionGridItem
                          value={rSim?.customerReference}
                          label={t('simInventory.customerReference')}
                          testId="customerReference"
                        />
                      </>
                    </SectionGridRow>
                  ) : null}
                  {clientVisible ? (
                    <SectionGridRow title={t('simInventory.client')}>
                      <>
                        <SectionGridItem
                          value={rSim?.accountName}
                          label={t('common.accountName')}
                          testId="accountName"
                        />
                        <SectionGridItem
                          value={rSim?.accountNumber}
                          label={t('common.accountNumber')}
                          testId="accountNumber"
                        />
                        <SectionGridItem
                          value={lastActiveRsimProfile?.customerCommsPlan}
                          label={t('simInventory.customerCommsPlan')}
                          testId="customerCommsPlan"
                        />
                      </>
                    </SectionGridRow>
                  ) : null}
                  {usageInCycleVisible ? (
                    <SectionGridRow title={t('simInventory.usageInCycle')}>
                      <>
                        <SectionGridItem
                          value={rSim?.dataUsage.toString() || '-'}
                          label={t('common.data')}
                          testId="dataUsage"
                        />
                        <SectionGridItem
                          value={rSim?.inMinuteUsage ? humanize(rSim?.inMinuteUsage) : '-'}
                          label={t('common.inMinuteUsage')}
                          testId="inVoiceUsage"
                        />
                        <SectionGridItem
                          value={rSim?.inTextUsage.toString() || '-'}
                          label={t('common.inTextUsage')}
                          testId="inSmsUsage"
                        />
                        <SectionGridItem
                          value={rSim?.outMinuteUsage ? humanize(rSim?.outMinuteUsage) : '-'}
                          label={t('common.outMinuteUsage')}
                          testId="outVoiceUsage"
                        />
                        <SectionGridItem
                          value={rSim?.outTextUsage.toString() || '-'}
                          label={t('common.outTextUsage')}
                          testId="outSmsUsage"
                        />
                      </>
                    </SectionGridRow>
                  ) : null}
                  {lastSessionDetailsVisible ? (
                    <SectionGridRow
                      data-testid="lastSessionDetails"
                      title={t('simInventory.lastSessionDetails')}
                    >
                      <>
                        <SectionGridItem
                          value={
                            simNameWithLastSession ?? (forcedLastSessionDetailsVisible as string)
                          }
                          label={t('common.profile')}
                          testId="profile"
                        />
                        <SectionGridItem
                          value={
                            getSessionStatusTranslatedLabel(simWithLastSession?.sessionStatus) ??
                            forcedLastSessionDetailsVisible
                          }
                          label={t('simInventory.sessionStatus')}
                          testId="sessionStatus"
                        />
                        <SectionGridItem
                          value={
                            simWithLastSession?.sessionStatusLastUpdated ??
                            forcedLastSessionDetailsVisible
                          }
                          label={t('simInventory.sessionStatusLastUpdated_flat')}
                          testId="sessionStatusLastUpdated"
                        />
                        <SectionGridItem
                          value={
                            simWithLastSession?.sessionStartTime ?? forcedLastSessionDetailsVisible
                          }
                          label={t('simInventory.sessionStartTime')}
                          testId="sessionStart"
                        />
                        <SectionGridItem
                          value={
                            simWithLastSession?.sessionEndTime ?? forcedLastSessionDetailsVisible
                          }
                          label={t('simInventory.sessionEndTime')}
                          testId="sessionEnd"
                        />
                        <SectionGridItem
                          value={
                            simWithLastSession?.cumulativeTotalUsage ??
                            forcedLastSessionDetailsVisible
                          }
                          label={t('simInventory.cumulativeUsage')}
                          testId="cumulativeUsage"
                        />
                        <SectionGridItem
                          value={simWithLastSession?.apn ?? forcedLastSessionDetailsVisible}
                          label={t('simInventory.apn')}
                          testId="apn"
                        />
                        <SectionGridItem
                          value={
                            simWithLastSession?.localization ?? forcedLastSessionDetailsVisible
                          }
                          label={t('simInventory.location')}
                          testId="location"
                        />
                        <SectionGridItem
                          value={simWithLastSession?.ratType ?? forcedLastSessionDetailsVisible}
                          label={t('simInventory.lastRat')}
                          testId="ratType"
                        />
                        <SectionGridItem
                          value={simWithLastSession?.rasClient ?? forcedLastSessionDetailsVisible}
                          label={t('simInventory.rasClient')}
                          testId="rasClient"
                        />
                        <SectionGridItem
                          value={
                            simWithLastSession?.framedProtocol ?? forcedLastSessionDetailsVisible
                          }
                          label={t('simInventory.framedProtocol')}
                          testId="framedProtocol"
                        />
                        <SectionGridItem
                          value={
                            simWithLastSession?.nasIpAddress ?? forcedLastSessionDetailsVisible
                          }
                          label={t('simInventory.nasIpAddress')}
                          testId="nasIpAddress"
                        />
                        <SectionGridItem
                          value={simWithLastSession?.serviceType ?? forcedLastSessionDetailsVisible}
                          label={t('simInventory.serviceType')}
                          testId="serviceType"
                        />
                        <SectionGridItem
                          value={simWithLastSession?.userName ?? forcedLastSessionDetailsVisible}
                          label={t('simInventory.userName')}
                          testId="userName"
                        />
                      </>
                    </SectionGridRow>
                  ) : null}
                </>
              </Grid>

              <RSimInventorySimDetails
                primarySimEntry={primarySimEntry}
                secondarySimEntry={secondarySimEntry}
                rSimStatus={rSim?.status!}
                euicc={euicc!}
              ></RSimInventorySimDetails>
            </Box>
          </KickRsimProvider>
        </ScrollArea>
      </Box>

      {euicc && (
        <RSimInventoryEditDialog
          open={editDialogOpen}
          onClose={handleOnClose}
          euicc={euicc}
          onTagsUpdate={handleTagsUpdate}
        />
      )}

      {euicc && configurationDialogOpen && (
        <Suspense>
          <RSimInventoryConfigurationDialog
            open={configurationDialogOpen}
            configuration={{
              euicc,
              appliedOtaConfigurationProfile: rSim?.appliedOtaConfigurationProfile || '',
              appliedOtaConfigurationVersion: rSim?.appliedOtaConfigurationVersion || '',
              isPendingConfiguration: rSim?.isPendingConfiguration || false,
              isCustomConfigurationDetected: rSim?.isCustomConfigurationDetected || false,
            }}
            onClose={handleOnClose}
            onConfigurationRestore={restoreConfiguration}
          />
        </Suspense>
      )}

      <AutoHideSnackbar
        severity="success"
        open={restoreStatus.success}
        onClose={() => setRestoreStatus({ ...restoreStatus, success: false })}
      >
        {''} {/* Without this component throw a error and crash app */}
        <AlertTitle> {t('rSimInventory.restoreConfigurationSend')}</AlertTitle>
      </AutoHideSnackbar>
      <AutoHideSnackbar
        severity="error"
        open={restoreStatus.error}
        onClose={() => setRestoreStatus({ ...restoreStatus, error: false })}
      >
        {''} {/* Without this component throw a error and crash app */}
        <AlertTitle>{t('rSimInventory.restoreConfigurationFailed')}</AlertTitle>
      </AutoHideSnackbar>
    </Stack>
  );
};
